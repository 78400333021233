import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { Button } from "reactstrap"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  getTestReport as onGetTestReport,
  getSampleDetail as onGetSampleDetail,
  updateTestReportInvoice as onUpdateTestReportInvoice,
} from "store/actions"

import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
import { isEmpty } from "lodash"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { getAuthUser } from "helpers/token_helper"
import { classname } from "classnames"
import QRCode from "qrcode.react"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"

function GenerateSampleCollectionReport() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()

  document.title = "Test Report"

  const [isLoading, setLoading] = useState(false)
  const [isViewMode, setIsViewMode] = useState(true)
  const [profile, setProfile] = useState({})
  const { id } = routeParams
  let counter = 0
  let index = 0

  /**
   * Form Validation Schema
   */
  const schema = yup.array().of(
    yup.object().shape({
      TestReportDetailID: yup.number(),
      ParameterID: yup.number(),
      Parameter: yup.string(),
      ParameterTypeID: yup.number(),
      Unit: yup.string(),
      Result: yup.string(),
      AcceptableLimit: yup.string(),
      PermissibleLimit: yup.string(),
      TestMethod: yup.string(),
    })
  )

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const { fields, append, remove } = useFieldArray({
    name: "report",
    control,
  })

  const {
    collection,
    booking,
    dispatched,
    testreport,
    sectionparameters,
    dgvisibility,
    loading,
  } = useSelector(state => state.sample)

  useDeepCompareEffect(() => {
    dispatch(onGetTestReport(id))
    //dispatch(onGetSampleDetail(id))
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [dispatch, testreport])

  const uniqueKeyValuePairs = sectionparameters.reduce((acc, obj) => {
    const { ParameterTypeID, ParameterType } = obj
    const key = `${ParameterTypeID}_${ParameterType}`
    if (!acc[key]) {
      acc[key] = { ParameterTypeID, ParameterType }
    }
    return acc
  }, {})

  useEffect(() => {
    if (isEmpty(sectionparameters)) {
      return
    }

    reset({ report: sectionparameters })
  }, [sectionparameters])

  const onCancelClick = () => {
    setIsViewMode(true)
  }

  const onBackClick = () => {
    navigate("/dispatches")
  }

  const AcceptableLimitColHeader = ({ sampleTypeID }) => {
    switch (sampleTypeID) {
      case 7:
        return "CPCB Std. limit"
      case 8:
        return "CPCB Std. limit"
      case 9:
        return "CPCB Std. limit"
      case 10:
        return "CPCB Std. limit"
      case 15:
        return "Standard Limit as per IS : 456"
      default:
        return "Acceptable Limit"
    }
  }

  const valuesToHideAcceptableLimit = [4, 11, 12, 13, 14]
  const valuesToHidePermissibleLimit = [4, 7, 8, 9, 10, 11, 12, 13, 14, 15]

  function shouldHideAcceptableLimit(SampleTypeID, valuesToHide) {
    return valuesToHide.includes(SampleTypeID)
  }

  function shouldHidePermissibleLimit(SampleTypeID, valuesToHide) {
    return valuesToHide.includes(SampleTypeID)
  }
  const divToPrint = useRef(null)
  const onPrintClick = () => {
    window.scrollTo({
      top: 0,
    })

    const printableArea = divToPrint.current

    if (printableArea) {
      window.print()
    }
  }

  useEffect(() => {
    const handleKeyDown = event => {
      // Check if Ctrl (or Cmd on Mac) + P is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        // Scroll to the top of the page
        window.scrollTo({ top: 0 })
      }
    }

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown)

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="printable-report mb-3">
          <Breadcrumbs title="Test Report" breadcrumbItem="Reports" />
          <div className="d-flex align-items-center justify-content-between no-print mb-3">
            <button onClick={onPrintClick} className="btn btn-success">
              Print
            </button>

            <div
              hidden={
                profile && profile.RoleID !== "2" && profile.RoleID !== "7"
              }
            >
              Invoice Generated :{" "}
              <Switch
                id="swInvoice"
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2"
                onChange={e => {
                  let request = {
                    ...testreport,
                    InvoiceGenerated: e,
                  }
                  dispatch(onUpdateTestReportInvoice(request))
                }}
                checked={testreport.InvoiceGenerated}
                onColor="#626ed4"
              />
            </div>
          </div>
          <div className="A4" ref={divToPrint}>
            <table className="tbl">
              <thead>
                <tr>
                  <td>
                    <div className="page-header"></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {collection && (
                      <>
                        <table
                          cellPadding={0}
                          cellSpacing={0}
                          className="table table-bordered"
                        >
                          <tr>
                            <td
                              className="border-start-0"
                              style={{ border: "none" }}
                              colSpan={2}
                            >
                              <div
                                hidden={!dispatched?.ULRNo}
                                style={{
                                  padding: 2,
                                }}
                              >
                                <b
                                  style={{
                                    padding: 0,
                                  }}
                                >
                                  U.L.R. No.:&nbsp;
                                </b>
                                {dispatched?.ULRNo}
                              </div>
                            </td>
                            <td
                              className="border-start-0"
                              style={{
                                border: "none",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  padding: 2,
                                }}
                              ></div>
                            </td>
                            <td
                              style={{ border: "none" }}
                              colSpan={2}
                              className="text-end"
                            >
                              <b
                                style={{
                                  padding: 0,
                                }}
                              >
                                Format No. :&nbsp;
                              </b>
                              GAASPL/QM/QSP/QF/7.8/01
                            </td>
                          </tr>
                          <tr className="text-center bg-secondary-subtle">
                            <td
                              colSpan={5}
                              style={{
                                padding: 0,
                                fontWeight: "700",
                                fontSize: 12,
                              }}
                            >
                              {/* {collection?.SampleType?.toUpperCase()}{" "} */}
                              TEST REPORT
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={2}
                              style={{
                                width: "30%",
                                borderTop: "none",
                              }}
                            >
                              Test Report No.:&nbsp;{testreport.TestReportNo}
                            </td>
                            <td colSpan={2}>
                              Date of Report:&nbsp;
                              {Moment(testreport.CreatedDate).format(
                                "DD-MM-yyyy"
                              )}
                            </td>
                            <td>Sample Code:&nbsp;{collection.SampleCode}</td>
                          </tr>
                          <tr className="text-center bg-secondary-subtle">
                            <td
                              colSpan={5}
                              style={{
                                padding: 0,
                                fontWeight: "700",
                                fontSize: 11,
                                borderBottom: "none",
                              }}
                            >
                              SAMPLE RECEIVED / COLLECTED FROM
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5} style={{ padding: 0 }}>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{ margin: 0 }}
                                className="table table-bordered border-0"
                              >
                                <tr>
                                  <td
                                    className="border-start-0"
                                    style={{
                                      width: "30%",
                                      borderTop: "none",
                                    }}
                                  >
                                    Name of Company/Customer{" "}
                                  </td>
                                  <td
                                    className="border-end-0"
                                    style={{
                                      borderTop: "none",
                                    }}
                                  >
                                    {collection.CompanyName}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="border-start-0"
                                    style={{ verticalAlign: "top" }}
                                  >
                                    Company/Customer Address
                                  </td>
                                  <td
                                    className="border-end-0"
                                    style={{ verticalAlign: "top" }}
                                  >
                                    {collection.AddressLine1}
                                    <br />
                                    {collection.AddressLine2}
                                    <br />
                                    {collection.City + ", " + collection.State}
                                  </td>
                                </tr>
                                <tr className="text-center bg-secondary-subtle">
                                  <td
                                    className="border-start-0 border-end-0 "
                                    colSpan={5}
                                    style={{
                                      padding: 0,
                                      fontWeight: "700",
                                      fontSize: 11,
                                      borderBottom: "none",
                                    }}
                                  >
                                    SAMPLE DESCRIPTION
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={5}
                              style={{ padding: 0, border: "none" }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{ margin: 0 }}
                                className="table table-bordered border-0"
                              >
                                <tr>
                                  <td
                                    className="border-start-0"
                                    style={{
                                      borderTop: "none",
                                      width: "30%",
                                    }}
                                  >
                                    Date of Sampling
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "none",
                                      width: "20%",
                                    }}
                                  >
                                    {Moment(collection.SampleDate).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "none",
                                      width: "30%",
                                    }}
                                  >
                                    Date of Sample Received in Laboratory
                                  </td>
                                  <td
                                    className="border-end-0"
                                    style={{ borderTop: "none" }}
                                  >
                                    {Moment(collection.IssuedDate).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-start-0">
                                    Start date of Analysis
                                  </td>
                                  <td> {testreport?.TestStartDate}</td>
                                  <td>End date of Analysis</td>
                                  <td className="border-end-0">
                                    {testreport.TestEndDate}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-start-0">
                                    Sample Type
                                  </td>
                                  <td>
                                    <b
                                      style={{
                                        padding: 0,
                                        fontSize: "10px",
                                      }}
                                    >
                                      {collection.SampleType}
                                    </b>
                                  </td>
                                  <td>Sample Source</td>
                                  <td className="border-end-0">
                                    {dispatched?.SampleSource}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-start-0">
                                    Source Capacity
                                  </td>
                                  <td>
                                    {collection.SourceCapacity
                                      ? collection.SourceCapacity
                                      : "NA"}
                                  </td>
                                  <td>Sampling Location</td>
                                  <td className="border-end-0">
                                    {collection.SampleLocation}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-start-0">
                                    Sampling Protocol/Method
                                  </td>
                                  <td>{collection.SamplingProtocol}</td>
                                  <td>Sample Quantity</td>
                                  <td className="border-end-0">
                                    {dispatched?.SampleQuantity}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-start-0">
                                    Sample Collected By
                                  </td>
                                  <td>{collection.ReceivedBy}</td>
                                  <td>
                                    Sample Condition at the time of receiving{" "}
                                  </td>
                                  <td className="border-end-0">
                                    {dispatched?.SampleConditionReceiving}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr className="text-center bg-secondary-subtle">
                            <td
                              colSpan={5}
                              style={{
                                padding: 0,
                                fontWeight: "700",
                                fontSize: 11,
                                border: "none",
                              }}
                            >
                              ONSITE OBSERVATIONS
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                padding: 0,
                                borderBottom: "none",
                              }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                style={{ margin: 0 }}
                                className="table table-bordered border-0"
                              >
                                <tr>
                                  <td
                                    className="border-start-0"
                                    style={{
                                      borderTop: "none",
                                      width: "30%",
                                    }}
                                  >
                                    Environmental Temperature at the time of
                                    sampling
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "none",
                                      width: "20%",
                                    }}
                                  >
                                    {collection?.SampleConditionSampling}
                                  </td>
                                  <td
                                    style={{
                                      borderTop: "none",
                                      width: "30%",
                                    }}
                                  >
                                    Preservation of Sample if Required(yes/no)
                                  </td>
                                  <td
                                    className="border-end-0"
                                    style={{ borderTop: "none" }}
                                  >
                                    {dispatched?.SamplePreservation}
                                  </td>
                                </tr>
                                {(collection?.SampleTypeID === 7 ||
                                  collection?.SampleTypeID === 16) && (
                                  <>
                                    <tr>
                                      <td className="border-start-0">
                                        Ambient Temperature° C
                                      </td>
                                      <td>{collection?.AmbientTemperature}</td>
                                      <td>Weather Condition</td>
                                      <td className="border-end-0">
                                        {collection?.WeatherCondition}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Relative Humidity %
                                      </td>
                                      <td>{collection?.RelativeHumidity}</td>
                                      <td>Indoor Temperature° C</td>
                                      <td className="border-end-0">
                                        {collection?.SampleTypeID === 7
                                          ? "NA"
                                          : collection?.IndoorTemperature}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Wind direction
                                      </td>
                                      <td>{collection?.WindDirection}</td>
                                      <td></td>
                                      <td className="border-end-0"></td>
                                    </tr>
                                  </>
                                )}
                                {collection?.SampleTypeID === 8 && (
                                  <>
                                    <tr>
                                      <td className="border-start-0">
                                        Type of Fuels
                                      </td>
                                      <td> {collection?.Fuel}</td>
                                      <td>Consumption of Fuels</td>
                                      <td className="border-end-0">
                                        {" "}
                                        {collection?.ConsumptionOfFuels}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Sampling Duration
                                      </td>
                                      <td>{collection?.SamplingDuration}</td>
                                      <td>Current Load</td>
                                      <td className="border-end-0">
                                        {collection?.CurrentLoad}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Flow Rate of SPM [LPM]
                                      </td>
                                      <td>{collection?.FlowRate}</td>
                                      <td>Flow Rate of Gases [LPM]</td>
                                      <td className="border-end-0">
                                        {collection?.FlowRateOfGases}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Stack height (from Ground level)
                                      </td>
                                      <td>
                                        {collection?.StackHeightGroundLevel}
                                      </td>
                                      <td>Stack height (from Roof level)</td>
                                      <td className="border-end-0">
                                        {collection?.StackHeightRoofLevel}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Stack Diameter (at Port hole)
                                      </td>
                                      <td>{collection?.StackDiameter}</td>
                                      <td>Average velocity [m/sec.]</td>
                                      <td className="border-end-0">
                                        {collection?.AverageVelocity}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Flew gas temperature. (°C)
                                      </td>
                                      <td>{collection?.FlewGasTemperature}</td>
                                      <td>Ambient temperature (°C)</td>
                                      <td className="border-end-0">
                                        {collection?.AmbientTemperature}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Quantity of Emission (m<sup>3</sup>
                                        /Hrs.)
                                      </td>
                                      <td>{collection?.QuantityOfEmission}</td>
                                      <td>Attached APCS</td>
                                      <td className="border-end-0">
                                        {collection?.SourceAPCS}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Stack Temperature
                                      </td>
                                      <td>{collection?.StackTemperature}</td>
                                      <td>Sampling height</td>
                                      <td className="border-end-0">
                                        {collection?.SamplingHeight}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border-start-0">
                                        Duration of Monitoring
                                      </td>
                                      <td>{collection?.MonitoringDuration}</td>
                                      <td></td>
                                      <td className="border-end-0"></td>
                                    </tr>
                                  </>
                                )}
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={5}
                              style={{ padding: 0, border: "none" }}
                            >
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                className="table table-bordered mb-0 border-0"
                              >
                                <tr className="text-center bg-secondary-subtle">
                                  <td
                                    style={{
                                      padding: 0,
                                      fontWeight: "700",
                                      fontSize: 11,
                                      border: "none",
                                    }}
                                  >
                                    TEST PARAMETERS
                                  </td>
                                </tr>
                              </table>
                              <table
                                cellPadding={0}
                                cellSpacing={0}
                                className="table table-bordered border-0 mb-0"
                              >
                                <thead>
                                  <tr className="bg-secondary-subtle fw-bold">
                                    <td
                                      className="p-0 text-center border-start-0"
                                      style={{
                                        width: "3%",
                                      }}
                                    >
                                      S.No
                                    </td>
                                    <td
                                      className="p-0 text-center"
                                      style={{
                                        width: "25%",
                                      }}
                                    >
                                      Parameters
                                    </td>
                                    <td
                                      className="p-0 text-center"
                                      style={{
                                        width: "10%",
                                      }}
                                    >
                                      Results
                                    </td>
                                    <td
                                      className="p-0 text-center"
                                      style={{
                                        width: "5%",
                                      }}
                                    >
                                      Unit
                                    </td>
                                    {collection?.SampleTypeID === 8 ? (
                                      <>
                                        {collection?.SourceTypeID === 1 && (
                                          <td
                                            className="p-0 text-center"
                                            style={{
                                              width: "10%",
                                            }}
                                          >
                                            Standard Limit
                                          </td>
                                        )}
                                        {collection?.SourceTypeID === 2 && (
                                          <>
                                            <td
                                              hidden={!dgvisibility.DGOne}
                                              className="p-0 text-center"
                                              style={{
                                                width: "8%",
                                              }}
                                            >
                                              {`NEW Standred limit DG SET < 19KW`}
                                            </td>
                                            <td
                                              hidden={!dgvisibility.DGTwo}
                                              style={{
                                                width: "8%",
                                              }}
                                              className="p-0 text-center"
                                            >
                                              {`NEW Standred limit DG SET < 75KW`}
                                            </td>
                                            <td
                                              hidden={!dgvisibility.DGThree}
                                              style={{
                                                width: "8%",
                                              }}
                                              className="p-0 text-center"
                                            >
                                              {`NEW Standred limit DG SET < 800KW `}
                                            </td>
                                            <td
                                              hidden={!dgvisibility.DGFour}
                                              style={{
                                                width: "8%",
                                              }}
                                              className="p-0 text-center"
                                            >
                                              {`Standred limit DG SET < 19KW(23.75KVA)`}
                                            </td>
                                            <td
                                              hidden={!dgvisibility.DGFive}
                                              style={{
                                                width: "8%",
                                              }}
                                              className="p-0 text-center"
                                            >
                                              {`Standred limit DG SET < 75KW(93.75KVA)`}
                                            </td>
                                            <td
                                              hidden={!dgvisibility.DGSix}
                                              style={{
                                                width: "8%",
                                              }}
                                              className="p-0 text-center"
                                            >
                                              {`Standred limit DG SET < 800KW(1000KVA) `}
                                            </td>
                                            <td
                                              hidden={!dgvisibility.DGSeven}
                                              style={{
                                                width: "8%",
                                              }}
                                              className="p-0 text-center"
                                            >
                                              {`Standred limit DG SET > 1000KVA `}
                                            </td>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {!shouldHideAcceptableLimit(
                                          collection.SampleTypeID,
                                          valuesToHideAcceptableLimit
                                        ) && (
                                          <td
                                            className="p-0 text-center"
                                            style={{ width: "10%" }}
                                          >
                                            <AcceptableLimitColHeader
                                              sampleTypeID={
                                                collection.SampleTypeID
                                              }
                                            />
                                          </td>
                                        )}
                                        {!shouldHidePermissibleLimit(
                                          collection.SampleTypeID,
                                          valuesToHidePermissibleLimit
                                        ) && (
                                          <td
                                            className="p-0 text-center"
                                            style={{ width: "10%" }}
                                          >
                                            Permissible Limit
                                          </td>
                                        )}
                                      </>
                                    )}
                                    <td
                                      className="p-0 text-center border-end-0"
                                      style={{
                                        width: "20%",
                                      }}
                                    >
                                      Test Method/Protocols
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sectionparameters &&
                                    Object.values(uniqueKeyValuePairs).map(
                                      (pr, i) => (
                                        <React.Fragment key={i}>
                                          <tr className="avoid-page-break">
                                            <td
                                              colSpan={
                                                collection?.SampleTypeID === 8
                                                  ? collection?.SourceTypeID ===
                                                    1
                                                    ? 6
                                                    : 12
                                                  : 7
                                              }
                                              className="border-0 text-center"
                                              style={{
                                                padding: 0,
                                                fontWeight: "700",
                                              }}
                                            >
                                              {pr.ParameterType}
                                            </td>
                                          </tr>
                                          {fields &&
                                            fields
                                              .filter(
                                                x =>
                                                  x.ParameterTypeID ===
                                                  pr.ParameterTypeID
                                              )
                                              .map((ch, j) => (
                                                <tr
                                                  key={
                                                    "child" +
                                                    ch.TestReportDetailID
                                                  }
                                                >
                                                  <td
                                                    className="border-start-0"
                                                    style={{
                                                      width: "3%",
                                                    }}
                                                  >
                                                    {(counter = counter + 1)}
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "25%",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html: ch.Parameter,
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      width: "10%",
                                                    }}
                                                  >
                                                    {ch.Result}
                                                  </td>
                                                  <td
                                                    className="p-0 text-center"
                                                    style={{
                                                      width: "5%",
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html: ch.Unit,
                                                    }}
                                                  ></td>
                                                  {collection?.SampleTypeID ===
                                                  8 ? (
                                                    <>
                                                      {collection?.SourceTypeID ===
                                                        1 && (
                                                        <td
                                                          className="p-0 text-center"
                                                          style={{
                                                            width: "10%",
                                                          }}
                                                        >
                                                          {
                                                            ch.SourceStandardLimit
                                                          }
                                                        </td>
                                                      )}
                                                      {collection?.SourceTypeID ===
                                                        2 && (
                                                        <>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGOne
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGOne}
                                                          </td>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGTwo
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGTwo}
                                                          </td>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGThree
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGThree}
                                                          </td>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGFour
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGFour}
                                                          </td>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGFive
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGFive}
                                                          </td>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGSix
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGSix}
                                                          </td>
                                                          <td
                                                            hidden={
                                                              !dgvisibility.DGSeven
                                                            }
                                                            className="p-0 text-center"
                                                          >
                                                            {ch.SourceDGSeven}
                                                          </td>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {!shouldHideAcceptableLimit(
                                                        collection.SampleTypeID,
                                                        valuesToHideAcceptableLimit
                                                      ) && (
                                                        <td className="p-0 text-center">
                                                          {ch.AcceptableLimit}
                                                        </td>
                                                      )}
                                                      {!shouldHidePermissibleLimit(
                                                        collection.SampleTypeID,
                                                        valuesToHidePermissibleLimit
                                                      ) && (
                                                        <td className="p-0 text-center">
                                                          {ch.PermissibleLimit}
                                                        </td>
                                                      )}
                                                    </>
                                                  )}
                                                  <td className="p-0 text-center border-end-0">
                                                    {ch.TestMethod}
                                                  </td>
                                                </tr>
                                              ))}
                                        </React.Fragment>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                background: "white",
                                borderTop: "none",
                                textAlign: "center",
                              }}
                            >
                              **End of Report**
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={5} style={{ background: "white" }}>
                              <p className="mb-2 p-0">
                                <strong>BDL</strong> - Below Detection Limit
                              </p>
                              <p className="p-0 mb-0">
                                <strong>Observation / Recommendation: </strong>
                                {dispatched?.Remark}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ paddingTop: "1mm" }}>
                    <div className="page-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex">
                          <div className="me-3" style={{ fontSize: "9pt" }}>
                            <br />
                            Checked By
                          </div>
                          <div>
                            <QRCode
                              value="https://greenadmire.com/files/CertificateTC-12856.pdf"
                              size={64}
                              style={{ padding: 0 }}
                              className="footer-qr me-2"
                            />
                            <QRCode
                              value="https://greenadmire.com/files/ScopeTC-12856.pdf"
                              size={64}
                              style={{ padding: 0 }}
                              className="footer-qr me-2"
                            />
                            <QRCode
                              value={
                                "Green Admire Analytical Services Pvt. Ltd. " +
                                testreport.TestReportNo +
                                ", " +
                                collection.SamplePlanID +
                                ", " +
                                collection.SampleCode
                              }
                              size={64}
                              style={{ padding: 0 }}
                              className="footer-qr"
                            />
                          </div>
                        </div>
                        <div className="text-end" style={{ fontSize: "9pt" }}>
                          Authorised By
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
GenerateSampleCollectionReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default GenerateSampleCollectionReport

import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  getDispatchDetail as onGetDispatchDetail,
  addNewDispatch as onAddNewDispatch,
  updateDispatch as onUpdateDispatch,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { RESET_DISPATCHED } from "store/sample/actionTypes"

function SampleDispatch() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const { id } = routeParams

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    SampleSectionID: yup.string().required("Required"),
    DispatchDate: yup.string().required("Required"),
    DispatchMode: yup.string().required("Required"),
    DispatchBy: yup.string().required("Required"),
    Remark: yup.string().nullable(),
    Active: yup.bool(),
    GenerateULR: yup.bool(),
  })

  const formdefault = {
    SampleSectionID: 0,
    DispatchDate: "",
    DispatchMode: "",
    DispatchBy: "",
    Remark: "",
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    dispatch(onGetDispatchDetail(id))
  }, [dispatch])

  const { collection, section, dispatched, loading } = useSelector(
    state => state.sample
  )

  useEffect(() => {
    if (id === "new") {
      reset(formdefault)
    } else {
      reset(dispatched)
    }
  }, [id, dispatched])

  const handleSave = () => {
    trigger() //for form validation
    //console.log(isValid)
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(
          onAddNewDispatch({
            ...getValues(),
            SampleSectionID: section.SampleSectionID,
          })
        )
      } else {
        dispatch(onUpdateDispatch(getValues()))
      }
      navigate("/dispatches")
    }
  }

  const onCancelClick = () => {
    if (id === "new") {
      navigate("/sections")
    } else {
      navigate("/dispatches")
    }
  }

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_DISPATCHED })
    }
  }, [])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dispatches" breadcrumbItem="Dispatch Details" />
          {routeParams.id !== "new" && isEmpty(dispatched) ? (
            <EmptyContainer
              backURL="/dispatches"
              message="There is no such dispatch!"
              linkText="Go to Dispatches Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormProvider {...methods}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <p>
                              LTR No - <strong>{getValues("LTRNo")}</strong>
                            </p>
                            <p>
                              Sample Code -
                              <strong>{getValues("SampleCode")}</strong>
                            </p>
                            <p>
                              Sample Type -
                              <strong>{getValues("SampleType")}</strong>
                            </p>
                            <p>
                              Sample Date -{" "}
                              <strong>{getValues("SampleDate")}</strong>
                            </p>
                            <p>
                              Sample Quantity -{" "}
                              <strong>{getValues("SampleQuantity")}</strong>
                            </p>
                            <div className="mb-3">
                              <p>
                                Active -
                                <Controller
                                  name="Active"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Switch
                                        {...field}
                                        id="Active"
                                        checked={field.value}
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#626ed4"
                                      />
                                    </>
                                  )}
                                />
                              </p>
                            </div>
                            <div className="mb-3">
                              <p>
                                Generate ULR No -
                                <Controller
                                  name="GenerateULR"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Switch
                                        {...field}
                                        id="GenerateULR"
                                        checked={field.value}
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#626ed4"
                                      />
                                    </>
                                  )}
                                />
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Dispatch Date
                                </label>
                                <Controller
                                  name="DispatchDate"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Flatpickr
                                        {...field}
                                        className="form-control d-block"
                                        id="DispatchDate"
                                        options={{
                                          dateFormat: "d M, Y",
                                        }}
                                        onChange={(
                                          selectedDates,
                                          dateStr,
                                          instance
                                        ) => {
                                          setValue("DispatchDate", dateStr)
                                        }}
                                        required
                                        invalid={!!errors.DispatchDate}
                                      />
                                      {errors?.DispatchDate?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.DispatchDate?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Dispatch Mode
                                </label>
                                <Controller
                                  name="DispatchMode"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="DispatchMode"
                                        type="text"
                                        required
                                        invalid={!!errors.DispatchMode}
                                      />
                                      {errors?.DispatchMode?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.DispatchMode?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Dispatch By
                                </label>
                                <Controller
                                  name="DispatchBy"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="DispatchBy"
                                        type="text"
                                        required
                                        invalid={!!errors.DispatchBy}
                                      />
                                      {errors?.DispatchBy?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.DispatchBy?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Observation / Recommendation
                                </label>
                                <Controller
                                  name="Remark"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <textarea
                                        {...field}
                                        id="Remark"
                                        className="form-control"
                                        rows={5}
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default SampleDispatch

import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_FAIL,
  DELETE_COLLECTION_SUCCESS,
  ADD_NEW_COLLECTION,
  ADD_COLLECTION_SUCCESS,
  ADD_COLLECTION_FAIL,
  GET_COLLECTION_DETAIL,
  GET_COLLECTION_DETAIL_SUCCESS,
  GET_COLLECTION_DETAIL_FAIL,
  GET_COLLECTION_PARAMETERS,
  GET_COLLECTION_PARAMETERS_SUCCESS,
  GET_COLLECTION_PARAMETERS_FAIL,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAIL,
  GET_SAMPLE_TYPES,
  GET_SAMPLE_TYPES_SUCCESS,
  GET_SAMPLE_TYPES_FAIL,
  GET_TEST_REPORT,
  GET_TEST_REPORT_SUCCESS,
  GET_TEST_REPORT_FAIL,
  UPDATE_TEST_REPORT,
  UPDATE_TEST_REPORT_SUCCESS,
  UPDATE_TEST_REPORT_FAIL,
  GET_SAMPLE_STATUSES,
  GET_SAMPLE_STATUSES_SUCCESS,
  GET_SAMPLE_STATUSES_FAIL,
  GET_PARAMETER_TYPES,
  GET_PARAMETER_TYPES_SUCCESS,
  GET_PARAMETER_TYPES_FAIL,
  GET_PARAMETERS,
  GET_PARAMETERS_SUCCESS,
  GET_PARAMETERS_FAIL,
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAIL,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL,
  ADD_NEW_BOOKING,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  GET_BOOKING_DETAIL,
  GET_BOOKING_DETAIL_SUCCESS,
  GET_BOOKING_DETAIL_FAIL,
  GET_SAMPLE_DETAIL,
  GET_SAMPLE_DETAIL_SUCCESS,
  GET_SAMPLE_DETAIL_FAIL,
  GET_SECTIONS,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_FAIL,
  GET_SECTION_DETAIL,
  GET_SECTION_DETAIL_SUCCESS,
  GET_SECTION_DETAIL_FAIL,
  DELETE_SECTION,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAIL,
  ADD_NEW_SECTION,
  ADD_SECTION_SUCCESS,
  ADD_SECTION_FAIL,
  UPDATE_SECTION,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAIL,
  GET_SECTION_TYPES,
  GET_SECTION_TYPES_SUCCESS,
  GET_SECTION_TYPES_FAIL,
  GET_SECTION_PARAMETERS,
  GET_SECTION_PARAMETERS_SUCCESS,
  GET_SECTION_PARAMETERS_FAIL,
  GET_DISPATCHES,
  GET_DISPATCHES_SUCCESS,
  GET_DISPATCHES_FAIL,
  GET_DISPATCH_DETAIL,
  GET_DISPATCH_DETAIL_SUCCESS,
  GET_DISPATCH_DETAIL_FAIL,
  DELETE_DISPATCH,
  DELETE_DISPATCH_SUCCESS,
  DELETE_DISPATCH_FAIL,
  ADD_NEW_DISPATCH,
  ADD_DISPATCH_SUCCESS,
  ADD_DISPATCH_FAIL,
  UPDATE_DISPATCH,
  UPDATE_DISPATCH_SUCCESS,
  UPDATE_DISPATCH_FAIL,
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_TESTREPORT_INVOICE,
  UPDATE_TESTREPORT_INVOICE_SUCCESS,
  UPDATE_TESTREPORT_INVOICE_FAIL,
  GET_SOURCES,
  GET_SOURCES_SUCCESS,
  GET_SOURCES_FAIL,
  GET_FUELS,
  GET_FUELS_SUCCESS,
  GET_FUELS_FAIL,
  GET_CONSUMPTIONS,
  GET_CONSUMPTIONS_SUCCESS,
  GET_CONSUMPTIONS_FAIL,
  GET_PROTOCOLS,
  GET_PROTOCOLS_SUCCESS,
  GET_PROTOCOLS_FAIL,
  GET_UNITS,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  GET_STANDARDLIMITS,
  GET_STANDARDLIMITS_SUCCESS,
  GET_STANDARDLIMITS_FAIL,
  GET_APCS,
  GET_APCS_SUCCESS,
  GET_APCS_FAIL,
  GET_DG_TYPES,
  GET_DG_TYPES_SUCCESS,
  GET_DG_TYPES_FAIL,
  GET_DG_STANDARDLIMITS,
  GET_DG_STANDARDLIMITS_SUCCESS,
  GET_DG_STANDARDLIMITS_FAIL,
  GET_DG_VISIBILITY,
  GET_DG_VISIBILITY_SUCCESS,
  GET_DG_VISIBILITY_FAIL,
} from "./actionTypes"

export const getCollections = () => ({
  type: GET_COLLECTIONS,
})

export const getCollectionsSuccess = collections => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: collections,
})

export const getCollectionsFail = error => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
})

export const deleteCollection = collection => ({
  type: DELETE_COLLECTION,
  payload: collection,
})

export const deleteCollectionSuccess = collection => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload: collection,
})

export const deleteCollectionFail = error => ({
  type: DELETE_COLLECTION_FAIL,
  payload: error,
})

export const addNewCollection = collection => ({
  type: ADD_NEW_COLLECTION,
  payload: collection,
})

export const addCollectionSuccess = collection => ({
  type: ADD_COLLECTION_SUCCESS,
  payload: collection,
})

export const addCollectionFail = error => ({
  type: ADD_COLLECTION_FAIL,
  payload: error,
})

export const getCollectionDetail = collectionId => ({
  type: GET_COLLECTION_DETAIL,
  collectionId,
})

export const getCollectionDetailSuccess = collection => ({
  type: GET_COLLECTION_DETAIL_SUCCESS,
  payload: collection,
})

export const getCollectionDetailFail = error => ({
  type: GET_COLLECTION_DETAIL_FAIL,
  payload: error,
})

export const updateCollection = collection => ({
  type: UPDATE_COLLECTION,
  payload: collection,
})

export const updateCollectionSuccess = collection => ({
  type: UPDATE_COLLECTION_SUCCESS,
  payload: collection,
})

export const updateCollectionFail = error => ({
  type: UPDATE_COLLECTION_FAIL,
  payload: error,
})

export const getSampleTypes = () => ({
  type: GET_SAMPLE_TYPES,
})

export const getSampleTypesSuccess = sampletypes => ({
  type: GET_SAMPLE_TYPES_SUCCESS,
  payload: sampletypes,
})

export const getSampleTypesFail = error => ({
  type: GET_SAMPLE_TYPES_FAIL,
  payload: error,
})

export const getTestReport = collectionId => ({
  type: GET_TEST_REPORT,
  payload: collectionId,
})

export const getTestReportSuccess = report => ({
  type: GET_TEST_REPORT_SUCCESS,
  payload: report,
})

export const getTestReportFail = error => ({
  type: GET_TEST_REPORT_FAIL,
  payload: error,
})

export const updateTestReport = (reportId, data) => ({
  type: UPDATE_TEST_REPORT,
  payload: { reportId, data },
})

export const updateTestReportSuccess = report => ({
  type: UPDATE_TEST_REPORT_SUCCESS,
  payload: report,
})

export const updateTestReportFail = error => ({
  type: UPDATE_TEST_REPORT_FAIL,
  payload: error,
})

export const getSampleStatuses = () => ({
  type: GET_SAMPLE_STATUSES,
})

export const getSampleStatusesSuccess = samplestatuses => ({
  type: GET_SAMPLE_STATUSES_SUCCESS,
  payload: samplestatuses,
})

export const getSampleStatusesFail = error => ({
  type: GET_SAMPLE_STATUSES_FAIL,
  payload: error,
})

export const getParameterTypes = () => ({
  type: GET_PARAMETER_TYPES,
})

export const getParameterTypesSuccess = parametertypes => ({
  type: GET_PARAMETER_TYPES_SUCCESS,
  payload: parametertypes,
})

export const getParameterTypesFail = error => ({
  type: GET_PARAMETER_TYPES_FAIL,
  payload: error,
})

export const getParameters = () => ({
  type: GET_PARAMETERS,
})

export const getParametersSuccess = parameters => ({
  type: GET_PARAMETERS_SUCCESS,
  payload: parameters,
})

export const getParametersFail = error => ({
  type: GET_PARAMETERS_FAIL,
  payload: error,
})

export const getBookings = () => ({
  type: GET_BOOKINGS,
})

export const getBookingsSuccess = bookings => ({
  type: GET_BOOKINGS_SUCCESS,
  payload: bookings,
})

export const getBookingsFail = error => ({
  type: GET_BOOKINGS_FAIL,
  payload: error,
})

export const deleteBooking = bookingId => ({
  type: DELETE_BOOKING,
  payload: bookingId,
})

export const deleteBookingSuccess = booking => ({
  type: DELETE_BOOKING_SUCCESS,
  payload: booking,
})

export const deleteBookingFail = error => ({
  type: DELETE_BOOKING_FAIL,
  payload: error,
})

export const addNewBooking = booking => ({
  type: ADD_NEW_BOOKING,
  payload: booking,
})

export const addBookingSuccess = booking => ({
  type: ADD_BOOKING_SUCCESS,
  payload: booking,
})

export const addBookingFail = error => ({
  type: ADD_BOOKING_FAIL,
  payload: error,
})

export const getBookingDetail = bookingId => ({
  type: GET_BOOKING_DETAIL,
  bookingId,
})

export const getBookingDetailSuccess = booking => ({
  type: GET_BOOKING_DETAIL_SUCCESS,
  payload: booking,
})

export const getBookingDetailFail = error => ({
  type: GET_BOOKING_DETAIL_FAIL,
  payload: error,
})

export const updateBooking = booking => ({
  type: UPDATE_BOOKING,
  payload: booking,
})

export const updateBookingSuccess = booking => ({
  type: UPDATE_BOOKING_SUCCESS,
  payload: booking,
})

export const updateBookingFail = error => ({
  type: UPDATE_BOOKING_FAIL,
  payload: error,
})

export const getSampleDetail = collectionId => ({
  type: GET_SAMPLE_DETAIL,
  collectionId,
})

export const getSampleDetailSuccess = data => ({
  type: GET_SAMPLE_DETAIL_SUCCESS,
  payload: data,
})

export const getSampleDetailFail = error => ({
  type: GET_SAMPLE_DETAIL_FAIL,
  payload: error,
})

export const getSections = () => ({
  type: GET_SECTIONS,
})

export const getSectionsSuccess = sections => ({
  type: GET_SECTIONS_SUCCESS,
  payload: sections,
})

export const getSectionsFail = error => ({
  type: GET_SECTIONS_FAIL,
  payload: error,
})

export const getSectionDetail = sectionId => ({
  type: GET_SECTION_DETAIL,
  sectionId,
})

export const getSectionDetailSuccess = section => ({
  type: GET_SECTION_DETAIL_SUCCESS,
  payload: section,
})

export const getSectionDetailFail = error => ({
  type: GET_SECTION_DETAIL_FAIL,
  payload: error,
})

export const deleteSection = sectionId => ({
  type: DELETE_SECTION,
  payload: sectionId,
})

export const deleteSectionSuccess = section => ({
  type: DELETE_SECTION_SUCCESS,
  payload: section,
})

export const deleteSectionFail = error => ({
  type: DELETE_SECTION_FAIL,
  payload: error,
})

export const addNewSection = section => ({
  type: ADD_NEW_SECTION,
  payload: section,
})

export const addSectionSuccess = section => ({
  type: ADD_SECTION_SUCCESS,
  payload: section,
})

export const addSectionFail = error => ({
  type: ADD_SECTION_FAIL,
  payload: error,
})

export const updateSection = section => ({
  type: UPDATE_SECTION,
  payload: section,
})

export const updateSectionSuccess = section => ({
  type: UPDATE_SECTION_SUCCESS,
  payload: section,
})

export const updateSectionFail = error => ({
  type: UPDATE_SECTION_FAIL,
  payload: error,
})
export const getSectionTypes = sampleTypeId => ({
  type: GET_SECTION_TYPES,
  payload: sampleTypeId,
})

export const getSectionTypesSuccess = sectiontypes => ({
  type: GET_SECTION_TYPES_SUCCESS,
  payload: sectiontypes,
})

export const getSectionTypesFail = error => ({
  type: GET_SECTION_TYPES_FAIL,
  payload: error,
})
export const getDispatches = () => ({
  type: GET_DISPATCHES,
})

export const getDispatchesSuccess = dispatches => ({
  type: GET_DISPATCHES_SUCCESS,
  payload: dispatches,
})

export const getDispatchesFail = error => ({
  type: GET_DISPATCHES_FAIL,
  payload: error,
})

export const getDispatchDetail = dispatchId => ({
  type: GET_DISPATCH_DETAIL,
  dispatchId,
})

export const getDispatchDetailSuccess = data => ({
  type: GET_DISPATCH_DETAIL_SUCCESS,
  payload: data,
})

export const getDispatchDetailFail = error => ({
  type: GET_DISPATCH_DETAIL_FAIL,
  payload: error,
})

export const deleteDispatch = dispatchId => ({
  type: DELETE_DISPATCH,
  payload: dispatchId,
})

export const deleteDispatchSuccess = dispatched => ({
  type: DELETE_DISPATCH_SUCCESS,
  payload: dispatched,
})

export const deleteDispatchFail = error => ({
  type: DELETE_DISPATCH_FAIL,
  payload: error,
})

export const addNewDispatch = dispatched => ({
  type: ADD_NEW_DISPATCH,
  payload: dispatched,
})

export const addDispatchSuccess = dispatched => ({
  type: ADD_DISPATCH_SUCCESS,
  payload: dispatched,
})

export const addDispatchFail = error => ({
  type: ADD_DISPATCH_FAIL,
  payload: error,
})

export const updateDispatch = dispatched => ({
  type: UPDATE_DISPATCH,
  payload: dispatched,
})

export const updateDispatchSuccess = dispatched => ({
  type: UPDATE_DISPATCH_SUCCESS,
  payload: dispatched,
})

export const updateDispatchFail = error => ({
  type: UPDATE_DISPATCH_FAIL,
  payload: error,
})
export const getCompanies = () => ({
  type: GET_COMPANIES,
})

export const getCompaniesSuccess = companies => ({
  type: GET_COMPANIES_SUCCESS,
  payload: companies,
})

export const getCompaniesFail = error => ({
  type: GET_COMPANIES_FAIL,
  payload: error,
})

export const getCompanyDetail = companyId => ({
  type: GET_COMPANY_DETAIL,
  companyId,
})

export const getCompanyDetailSuccess = company => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  payload: company,
})

export const getCompanyDetailFail = error => ({
  type: GET_COMPANY_DETAIL_FAIL,
  payload: error,
})

export const deleteCompany = companyId => ({
  type: DELETE_COMPANY,
  payload: companyId,
})

export const deleteCompanySuccess = company => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: company,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})

export const addNewCompany = company => ({
  type: ADD_NEW_COMPANY,
  payload: company,
})

export const addCompanySuccess = company => ({
  type: ADD_COMPANY_SUCCESS,
  payload: company,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = company => ({
  type: UPDATE_COMPANY,
  payload: company,
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const getCollectionParameters = (
  sampleTypeId,
  collectionId,
  sectionTypeId
) => ({
  type: GET_COLLECTION_PARAMETERS,
  payload: { collectionId, sampleTypeId, sectionTypeId },
})

export const getCollectionParametersSuccess = collectionparameters => ({
  type: GET_COLLECTION_PARAMETERS_SUCCESS,
  payload: collectionparameters,
})

export const getCollectionParametersFail = error => ({
  type: GET_COLLECTION_PARAMETERS_FAIL,
  payload: error,
})

export const getSectionParameters = sectionId => ({
  type: GET_SECTION_PARAMETERS,
  payload: sectionId,
})

export const getSectionParametersSuccess = sectionparameters => ({
  type: GET_SECTION_PARAMETERS_SUCCESS,
  payload: sectionparameters,
})

export const getSectionParametersFail = error => ({
  type: GET_SECTION_PARAMETERS_FAIL,
  payload: error,
})

export const updateTestReportInvoice = request => ({
  type: UPDATE_TESTREPORT_INVOICE,
  payload: request,
})

export const updateTestReportInvoiceSuccess = report => ({
  type: UPDATE_TESTREPORT_INVOICE_SUCCESS,
  payload: report,
})

export const updateTestReportInvoiceFail = error => ({
  type: UPDATE_TESTREPORT_INVOICE_FAIL,
  payload: error,
})

export const getSources = sampleTypeId => ({
  type: GET_SOURCES,
  payload: sampleTypeId,
})

export const getSourcesSuccess = sources => ({
  type: GET_SOURCES_SUCCESS,
  payload: sources,
})

export const getSourcesFail = error => ({
  type: GET_SOURCES_FAIL,
  payload: error,
})

export const getFuels = sourceTypeId => ({
  type: GET_FUELS,
  payload: sourceTypeId,
})

export const getFuelsSuccess = fuels => ({
  type: GET_FUELS_SUCCESS,
  payload: fuels,
})

export const getFuelsFail = error => ({
  type: GET_FUELS_FAIL,
  payload: error,
})

export const getConsumptions = sourceTypeId => ({
  type: GET_CONSUMPTIONS,
  payload: sourceTypeId,
})

export const getConsumptionsSuccess = consumptions => ({
  type: GET_CONSUMPTIONS_SUCCESS,
  payload: consumptions,
})

export const getConsumptionsFail = error => ({
  type: GET_CONSUMPTIONS_FAIL,
  payload: error,
})

export const getProtocols = sourceTypeId => ({
  type: GET_PROTOCOLS,
  payload: sourceTypeId,
})

export const getProtocolsSuccess = protocols => ({
  type: GET_PROTOCOLS_SUCCESS,
  payload: protocols,
})

export const getProtocolsFail = error => ({
  type: GET_PROTOCOLS_FAIL,
  payload: error,
})

export const getUnits = sourceTypeId => ({
  type: GET_UNITS,
  payload: sourceTypeId,
})

export const getUnitsSuccess = units => ({
  type: GET_UNITS_SUCCESS,
  payload: units,
})

export const getUnitsFail = error => ({
  type: GET_UNITS_FAIL,
  payload: error,
})

export const getStandardLimits = sourceTypeId => ({
  type: GET_STANDARDLIMITS,
  payload: sourceTypeId,
})

export const getStandardLimitsSuccess = standardlimits => ({
  type: GET_STANDARDLIMITS_SUCCESS,
  payload: standardlimits,
})

export const getStandardLimitsFail = error => ({
  type: GET_STANDARDLIMITS_FAIL,
  payload: error,
})

export const getAPCS = sourceTypeId => ({
  type: GET_APCS,
  payload: sourceTypeId,
})

export const getAPCSSuccess = apcs => ({
  type: GET_APCS_SUCCESS,
  payload: apcs,
})

export const getAPCSFail = error => ({
  type: GET_APCS_FAIL,
  payload: error,
})

export const getDGtypes = () => ({
  type: GET_DG_TYPES,
})

export const getDGtypesSuccess = dgtypes => ({
  type: GET_DG_TYPES_SUCCESS,
  payload: dgtypes,
})

export const getDGtypesFail = error => ({
  type: GET_DG_TYPES_FAIL,
  payload: error,
})

export const getDGStandardLimits = () => ({
  type: GET_DG_STANDARDLIMITS,
})

export const getDGStandardLimitsSuccess = dgstandardlimits => ({
  type: GET_DG_STANDARDLIMITS_SUCCESS,
  payload: dgstandardlimits,
})

export const getDGStandardLimitsFail = error => ({
  type: GET_DG_STANDARDLIMITS_FAIL,
  payload: error,
})

export const getDGVisibility = collectionId => ({
  type: GET_DG_VISIBILITY,
  payload: collectionId,
})

export const getDGVisibilitySuccess = result => ({
  type: GET_DG_VISIBILITY_SUCCESS,
  payload: result,
})

export const getDGVisibilityFail = error => ({
  type: GET_DG_VISIBILITY_FAIL,
  payload: error,
})

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import Moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getBookings as onGetBookings,
  deleteBooking as onDeleteBooking,
} from "store/actions"

import { getAuthUser } from "helpers/token_helper"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

function Booking() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})

  const { bookings, loading } = useSelector(state => state.sample)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [booking, setBooking] = useState(null)

  useEffect(() => {
    if (bookings && !bookings.length) {
      dispatch(onGetBookings())
    }

    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [dispatch, bookings])

  const onClickDelete = arg => {
    setBooking(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (booking && booking.SampleBookingID) {
      dispatch(onDeleteBooking(booking.SampleBookingID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const handleAddClick = () => {
    navigate("/booking/new")
  }

  const CollectionStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "LTR No",
        accessor: "LTRNo",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.LTRNo}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Sample Plan ID",
        accessor: "SamplePlanID",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Sample Type",
        accessor: "SampleType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "Active",
        Cell: cellProps => {
          return <CollectionStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/booking/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                hidden={true}
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              {/* <Link
                to={
                  "/section/" +
                  cellProps.row.original.SectionRowID +
                  "/" +
                  cellProps.row.original.CollectionRowID
                }
                className="text-success"
                hidden={
                  profile && (profile.RoleID === "3" || profile.RoleID === "5")
                }
              >
                Go to Section
              </Link> */}
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Bookings" breadcrumbItem="Booking List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={bookings}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add Booking"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={true}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Booking.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Booking

import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postLogin,
  postSocialLogin,
} from "../../../helpers/backend_helper"
import { encryptData } from "helpers/token_helper"
import axios from "axios"

// const response = {
//   access_token:
//     "9tB8BUQkBzXPbr01sPQANBjvwTzoEkGwrnqCAcHJfVOTc105SNZmnlKJimhQqUt5t8ubr6g6-D-HRdxXo409Xb3DWLYRFc0bN5eGvxPOVQeE0gzwFv5RZZiwb-AETFL3W1IawnBK8BxCrFJTLjoMr8HDGjeL561Le64zg7q2KNWcHmeevCAJLLewyc9mXm0S2Ov0sgrTirgfHmKWMdv4qJTCqFHk3aD6-TxZNNYEKrOhznwEI46RwzmeRtO1QQB0BJI57Xnl2OShzlbfXMchnwh52udffT44iQuhyRSpAfopQbSggk_phkOkoCCdgVNau8HA3Ay-7ZSMfGS94MzlfZII2T_DAiNk4yXu30VkwTUKVJXiDofEQ146Gp-cM8rrspn2q4iuKH2LqDrvVRvdWg",
//   token_type: "bearer",
//   expires_in: 1209599,
//   refresh_token: "5bcc1623-848f-41d2-a7a5-767d1f2ee588",
//   username: "Sachin Kumar",
//   RowID: "98FE76B0-ADF0-4002-BA30-6F2D68369B11",
//   ".issued": "Tue, 13 Feb 2024 05:51:25 GMT",
//   ".expires": "Tue, 27 Feb 2024 05:51:25 GMT",
// }

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postLogin, user)
      localStorage.setItem("authUser", encryptData(response))
      yield put(loginSuccess(response))
    }
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    delete axios.defaults.headers.common.Authorization

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewSection as onAddNewSection,
  updateSection as onUpdateSection,
  getSectionDetail as onGetSectionDetail,
  getSectionParameters as onGetSectionParameters,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { RESET_COLLECTION } from "store/sample/actionTypes"

function SampleSection() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const { id } = routeParams
  let counter = 0
  let index = 0

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    SampleBookingID: yup.string().required("Required"),
    TestStartDate: yup.string().required("Required"),
    TestEndDate: yup.string().required("Required"),
    LTRReceivedBy: yup.string().required("Required"),
    LTRReturnTo: yup.string().required("Required"),
    Remark: yup.string(),
    Active: yup.bool(),
    SectionParameters: yup.array().of(
      yup.object().shape({
        ParameterID: yup.number(),
        Parameter: yup.string(),
        ParameterTypeID: yup.number(),
        Result: yup.string(),
      })
    ),
  })

  const formdefault = {
    SampleBookingID: 0,
    TestStartDate: "",
    TestEndDate: "",
    LTRReceivedBy: "",
    LTRReturnTo: "",
    Remark: "",
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "SectionParameters",
    control,
  })

  const { collection, booking, section, sectionparameters, loading } =
    useSelector(state => state.sample)

  useDeepCompareEffect(() => {
    dispatch(onGetSectionDetail(id))
    dispatch(onGetSectionParameters(id))
  }, [dispatch])

  useEffect(() => {
    if (id === "new") {
      reset(formdefault)
    } else {
      //console.log(section)
      reset({ ...section, SectionParameters: sectionparameters })
    }
  }, [id, section, sectionparameters])

  const uniqueKeyValuePairs = sectionparameters.reduce((acc, obj) => {
    const { ParameterTypeID, ParameterType } = obj
    const key = `${ParameterTypeID}_${ParameterType}`
    if (!acc[key]) {
      acc[key] = { ParameterTypeID, ParameterType }
    }
    return acc
  }, {})

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewSection(getValues()))
      } else {
        dispatch(onUpdateSection(getValues()))
      }
      navigate("/sections")
    }
  }

  const onCancelClick = () => {
    if (id === "new") {
      navigate("/bookings")
    } else {
      navigate("/sections")
    }
  }

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_COLLECTION })
    }
  }, [])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Sections" breadcrumbItem="Section Details" />
          {routeParams.id !== "new" && isEmpty(section) ? (
            <EmptyContainer
              backURL="/sections"
              message="There is no such section!"
              linkText="Go to Sections Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormProvider {...methods}>
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="mb-3">
                            Section Type -{" "}
                            <strong>{getValues("SectionType")}</strong>
                          </div>
                          <div className="mb-3">
                            <p>
                              LTR No - <strong>{getValues("LTRNo")}</strong>
                            </p>
                            <p>
                              Sample Plan ID -{" "}
                              <strong>{getValues("SampleCode")}</strong>
                            </p>
                            <p>
                              Sample Type -{" "}
                              <strong>{getValues("SampleType")}</strong>
                            </p>
                            <p>
                              Sample Date -{" "}
                              <strong>{getValues("SampleDate")}</strong>
                            </p>
                            <p>
                              Sample Quantity -{" "}
                              <strong>{getValues("SampleQuantity")}</strong>
                            </p>
                            <div className="mb-3">
                              <p>
                                Active -{" "}
                                <Controller
                                  name="Active"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Switch
                                        {...field}
                                        id="Active"
                                        checked={field.value}
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#626ed4"
                                      />
                                    </>
                                  )}
                                />
                              </p>

                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="">
                            <div className="mb-3">
                              <label className="form-label">
                                Test Start Date
                              </label>
                              <Controller
                                name="TestStartDate"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Flatpickr
                                      {...field}
                                      className="form-control d-block"
                                      id="TestStartDate"
                                      options={{
                                        dateFormat: "d M, Y",
                                      }}
                                      onChange={(
                                        selectedDates,
                                        dateStr,
                                        instance
                                      ) => {
                                        setValue("TestStartDate", dateStr)
                                      }}
                                      required
                                      invalid={!!errors.TestStartDate}
                                    />
                                    {errors?.TestStartDate?.message ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {errors?.TestStartDate?.message}
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="mb-3">
                              <label className="form-label">
                                Test End Date
                              </label>
                              <Controller
                                name="TestEndDate"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Flatpickr
                                      {...field}
                                      className="form-control d-block"
                                      id="TestEndDate"
                                      options={{
                                        dateFormat: "d M, Y",
                                      }}
                                      onChange={(
                                        selectedDates,
                                        dateStr,
                                        instance
                                      ) => {
                                        setValue("TestEndDate", dateStr)
                                      }}
                                      required
                                      invalid={!!errors.TestEndDate}
                                    />
                                    {errors?.TestEndDate?.message ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {errors?.TestEndDate?.message}
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="mb-3">
                              <label className="form-label">
                                LTR Received By
                              </label>
                              <Controller
                                name="LTRReceivedBy"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Input
                                      {...field}
                                      id="LTRReceivedBy"
                                      type="text"
                                      required
                                      invalid={!!errors.LTRReceivedBy}
                                    />
                                    {errors?.LTRReceivedBy?.message ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {errors?.LTRReceivedBy?.message}
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="">
                            <div className="mb-3">
                              <label className="form-label">
                                LTR Return To
                              </label>
                              <Controller
                                name="LTRReturnTo"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Input
                                      {...field}
                                      id="LTRReturnTo"
                                      type="text"
                                      required
                                      invalid={!!errors.LTRReturnTo}
                                    />
                                    {errors?.LTRReturnTo?.message ? (
                                      <FormFeedback
                                        type="invalid"
                                        className="d-block"
                                      >
                                        {errors?.LTRReturnTo?.message}
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="">
                            <div className="mb-3">
                              <label className="form-label">Remark</label>
                              <Controller
                                name="Remark"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <textarea
                                      {...field}
                                      id="Remark"
                                      className="form-control"
                                      rows={5}
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <table
                        style={{ marginTop: 15 }}
                        className="table table-bordered"
                      >
                        {!isEmpty(sectionparameters) &&
                          Object.values(uniqueKeyValuePairs).map((pr, i) => (
                            <>
                              <tr key={"parent" + i}>
                                <td
                                  colSpan={3}
                                  style={{
                                    padding: 5,
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }}
                                >
                                  {pr.ParameterType}
                                </td>
                              </tr>
                              {fields &&
                                fields
                                  .filter(
                                    x =>
                                      x.ParameterTypeID === pr.ParameterTypeID
                                  )
                                  .map((ch, j) => {
                                    return (
                                      <>
                                        <tr key={"child" + ch.ParameterID}>
                                          <td style={{ width: 60 }}>
                                            {(counter = counter + 1)}
                                          </td>
                                          <td>
                                            {ch.Parameter} - {ch.Unit}
                                          </td>
                                          <td>
                                            <div>
                                              <Controller
                                                name={`SectionParameters[${index}].Result`}
                                                control={control}
                                                render={({ field }) => (
                                                  <>
                                                    <Input
                                                      {...field}
                                                      id={`SectionParameters[${index}].Result`}
                                                      type="text"
                                                    />
                                                  </>
                                                )}
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                        <span hidden={true}>
                                          {(index = index + 1)}
                                        </span>
                                      </>
                                    )
                                  })}
                            </>
                          ))}
                      </table>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default SampleSection

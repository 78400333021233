import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewCompany as onAddNewCompany,
  getCompanyDetail as onGetCompanyDetail,
  updateCompany as onUpdateCompany,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"

function CompanyAddEdit() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const { id } = routeParams
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CompanyName: yup.string().required("Required"),
    AddressLine1: yup.string().required("Required"),
    AddressLine2: yup.string().required("Required"),
    City: yup.string().required("Required"),
    State: yup.string().required("Required"),
    PinCode: yup.string().required("Required"),
    EmailAddress: yup.string(),
    ContactMobile: yup.string(),
    ContactPhone: yup.string(),
    Active: yup.bool(),
  })

  const formdefault = {
    CompanyName: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    PinCode: "",
    EmailAddress: "",
    ContactMobile: "",
    ContactPhone: "",
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    dispatch(onGetCompanyDetail(id))
  }, [dispatch])

  const { company, loading } = useSelector(state => state.sample)

  useEffect(() => {
    if (id === "new") {
      reset(formdefault)
    } else {
      reset(company)
    }
  }, [id, company])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewCompany(getValues()))
      } else {
        dispatch(onUpdateCompany(getValues()))
      }
      navigate("/companies")
    }
  }

  const onCancelClick = () => {
    navigate("/companies")
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Companies" breadcrumbItem="Company Details" />
          {routeParams.id !== "new" && isEmpty(company) ? (
            <EmptyContainer
              backURL="/companies"
              message="There is no such company!"
              linkText="Go to Companies Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormProvider {...methods}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Customer/Company Name
                            </label>
                            <Controller
                              name="CompanyName"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="CompanyName"
                                    type="text"
                                    required
                                    invalid={!!errors.CompanyName}
                                  />
                                  {errors?.CompanyName?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.CompanyName?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Address Line 1</label>
                            <Controller
                              name="AddressLine1"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <textarea
                                    {...field}
                                    id="AddressLine1"
                                    className="form-control"
                                    rows={3}
                                  />
                                  {errors?.AddressLine1?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.AddressLine1?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Address Line 2</label>
                            <Controller
                              name="AddressLine2"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <textarea
                                    {...field}
                                    id="AddressLine2"
                                    className="form-control"
                                    rows={3}
                                  />
                                  {errors?.AddressLine2?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.AddressLine2?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">City</label>
                            <Controller
                              name="City"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input {...field} id="City" type="text" />
                                  {errors?.City?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.City?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">State</label>
                            <Controller
                              name="State"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input {...field} id="State" type="text" />
                                  {errors?.State?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.State?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Pin Code</label>
                            <Controller
                              name="PinCode"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input {...field} id="PinCode" type="text" />
                                  {errors?.PinCode?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.PinCode?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Controller
                              name="EmailAddress"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="EmailAddress"
                                    className="form-control"
                                  />
                                  {errors?.EmailAddress?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.EmailAddress?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Contact Mobile</label>
                            <Controller
                              name="ContactMobile"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="ContactMobile"
                                    className="form-control"
                                  />
                                </>
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">Contact Person</label>
                            <Controller
                              name="ContactPerson"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="ContactPerson"
                                    className="form-control"
                                  />
                                </>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <Label>Active</Label>
                            <div>
                              <Controller
                                name="Active"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Switch
                                      {...field}
                                      id="Active"
                                      checked={field.value}
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default CompanyAddEdit

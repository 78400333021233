import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import Moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getCollections as onGetCollections,
  deleteCollection as onDeleteCollection,
} from "store/actions"

import { getAuthUser } from "helpers/token_helper"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

function Collection() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})

  const { collections, loading } = useSelector(state => state.sample)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [collection, setCollection] = useState(null)

  useEffect(() => {
    if (collections && !collections.length) {
      dispatch(onGetCollections())
    }

    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [dispatch, collections])

  const handleAddClick = () => {
    navigate("/collection/new")
  }

  const onClickDelete = arg => {
    setCollection(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (collection && collection.SampleCollectionID) {
      dispatch(onDeleteCollection(collection.SampleCollectionID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const CollectionStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "CompanyName",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.CompanyName}
                  <br />
                  <p className="text-muted mb-0">
                    {cellProps.row.original.AddressLine1} <br />
                    {cellProps.row.original.City +
                      ", " +
                      cellProps.row.original.AddressLine2}
                    <br />
                    {cellProps.row.original.State +
                      ", " +
                      cellProps.row.original.PinCode}
                  </p>
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Sample Plan ID",
        accessor: "SamplePlanID",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Sample Type",
        accessor: "SampleType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "Active",
        Cell: cellProps => {
          return <CollectionStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/collection/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                hidden={true}
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              <Link
                to={"/print-collection/" + cellProps.row.original.RowID}
                className="text-success"
              >
                Print
              </Link>
              {/* <Link
                to={
                  "/booking/" +
                  cellProps.row.original.BookingRowID +
                  "/" +
                  cellProps.row.original.RowID
                }
                className="text-success"
                hidden={
                  profile && (profile.RoleID === "3" || profile.RoleID === "5")
                }
              >
                Manage Booking
              </Link> */}
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Collections"
            breadcrumbItem="Sample Collection List"
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={collections}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add Sample"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={true}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Collection.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Collection

import { call, put, takeEvery } from "redux-saga/effects"

// Staff Redux States
import { GET_ROLES } from "./actionTypes"
import { getRolesSuccess, getRolesFail } from "./actions"

//Include Both Helper File with needed methods
import { getRoles } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchRoles() {
  try {
    const response = yield call(getRoles)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
}

export default roleSaga

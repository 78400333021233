/*Get COLLECTION */
export const GET_COLLECTIONS = "GET_COLLECTIONS"
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS"
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL"

/**
 * Delete COLLECTION
 */
export const DELETE_COLLECTION = "DELETE_COLLECTION"
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS"
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL"

/**
 * Add COLLECTION
 */
export const ADD_NEW_COLLECTION = "ADD_NEW_COLLECTION"
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS"
export const ADD_COLLECTION_FAIL = "ADD_COLLECTION_FAIL"

/**
 * Get COLLECTION DETAILS
 */
export const GET_COLLECTION_DETAIL = "GET_COLLECTION_DETAIL"
export const GET_COLLECTION_DETAIL_SUCCESS = "GET_COLLECTION_DETAIL_SUCCESS"
export const GET_COLLECTION_DETAIL_FAIL = "GET_COLLECTION_DETAIL_FAIL"

/**
 * Edit COLLECTION
 */
export const UPDATE_COLLECTION = "UPDATE_COLLECTION"
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS"
export const UPDATE_COLLECTION_FAIL = "UPDATE_COLLECTION_FAIL"

/**
 * Get COLLECTION PARAMETERS DETAILS
 */
export const GET_COLLECTION_PARAMETERS = "GET_COLLECTION_PARAMETERS"
export const GET_COLLECTION_PARAMETERS_SUCCESS =
  "GET_COLLECTION_PARAMETERS_SUCCESS"
export const GET_COLLECTION_PARAMETERS_FAIL = "GET_COLLECTION_PARAMETERS_FAIL"

/*Get BOOKING */
export const GET_BOOKINGS = "GET_BOOKINGS"
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS"
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL"

/**
 * Delete BOOKING
 */
export const DELETE_BOOKING = "DELETE_BOOKING"
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS"
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL"

/**
 * Add BOOKING
 */
export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING"
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS"
export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL"

/**
 * Get BOOKING DETAILS
 */
export const GET_BOOKING_DETAIL = "GET_BOOKING_DETAIL"
export const GET_BOOKING_DETAIL_SUCCESS = "GET_BOOKING_DETAIL_SUCCESS"
export const GET_BOOKING_DETAIL_FAIL = "GET_BOOKING_DETAIL_FAIL"

/**
 * Update BOOKING
 */
export const UPDATE_BOOKING = "UPDATE_BOOKING"
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS"
export const UPDATE_BOOKING_FAIL = "UPDATE_BOOKING_FAIL"

/*Get SAMPLE TYPES */
export const GET_SAMPLE_TYPES = "GET_SAMPLE_TYPES"
export const GET_SAMPLE_TYPES_SUCCESS = "GET_SAMPLE_TYPES_SUCCESS"
export const GET_SAMPLE_TYPES_FAIL = "GET_SAMPLE_TYPES_FAIL"

/*Get SAMPLE STATUSES */
export const GET_SAMPLE_STATUSES = "GET_SAMPLE_STATUSES"
export const GET_SAMPLE_STATUSES_SUCCESS = "GET_SAMPLE_STATUSES_SUCCESS"
export const GET_SAMPLE_STATUSES_FAIL = "GET_SAMPLE_STATUSES_FAIL"

/*Get PARAMETERS */
export const GET_PARAMETERS = "GET_PARAMETERS"
export const GET_PARAMETERS_SUCCESS = "GET_PARAMETERS_SUCCESS"
export const GET_PARAMETERS_FAIL = "GET_PARAMETERS_FAIL"

/*Get PARAMETERS TYPES */
export const GET_PARAMETER_TYPES = "GET_PARAMETER_TYPES"
export const GET_PARAMETER_TYPES_SUCCESS = "GET_PARAMETER_TYPES_SUCCESS"
export const GET_PARAMETER_TYPES_FAIL = "GET_PARAMETER_TYPES_FAIL"

/*Get TEST REPORT */
export const GET_TEST_REPORT = "GET_TEST_REPORT"
export const GET_TEST_REPORT_SUCCESS = "GET_TEST_REPORT_SUCCESS"
export const GET_TEST_REPORT_FAIL = "GET_TEST_REPORT_FAIL"

/**
 * Edit TEST REPORT
 */
export const UPDATE_TEST_REPORT = "UPDATE_TEST_REPORT"
export const UPDATE_TEST_REPORT_SUCCESS = "UPDATE_TEST_REPORT_SUCCESS"
export const UPDATE_TEST_REPORT_FAIL = "UPDATE_TEST_REPORT_FAIL"

/**
 * Get SAMPLE DETAILS
 */
export const GET_SAMPLE_DETAIL = "GET_SAMPLE_DETAIL"
export const GET_SAMPLE_DETAIL_SUCCESS = "GET_SAMPLE_DETAIL_SUCCESS"
export const GET_SAMPLE_DETAIL_FAIL = "GET_SAMPLE_DETAIL_FAIL"

/*Get SECTION */
export const GET_SECTIONS = "GET_SECTIONS"
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS"
export const GET_SECTIONS_FAIL = "GET_SECTIONS_FAIL"

/**
 * Get SECTION DETAILS
 */
export const GET_SECTION_DETAIL = "GET_SECTION_DETAIL"
export const GET_SECTION_DETAIL_SUCCESS = "GET_SECTION_DETAIL_SUCCESS"
export const GET_SECTION_DETAIL_FAIL = "GET_SECTION_DETAIL_FAIL"

/**
 * Delete SECTION
 */
export const DELETE_SECTION = "DELETE_SECTION"
export const DELETE_SECTION_SUCCESS = "DELETE_SECTION_SUCCESS"
export const DELETE_SECTION_FAIL = "DELETE_SECTION_FAIL"

/**
 * Add SECTION
 */
export const ADD_NEW_SECTION = "ADD_NEW_SECTION"
export const ADD_SECTION_SUCCESS = "ADD_SECTION_SUCCESS"
export const ADD_SECTION_FAIL = "ADD_SECTION_FAIL"

/**
 * Update SECTION
 */
export const UPDATE_SECTION = "UPDATE_SECTION"
export const UPDATE_SECTION_SUCCESS = "UPDATE_SECTION_SUCCESS"
export const UPDATE_SECTION_FAIL = "UPDATE_SECTION_FAIL"

/**
 * Get SECTION PARAMETERS DETAILS
 */
export const GET_SECTION_PARAMETERS = "GET_SECTION_PARAMETERS"
export const GET_SECTION_PARAMETERS_SUCCESS = "GET_SECTION_PARAMETERS_SUCCESS"
export const GET_SECTION_PARAMETERS_FAIL = "GET_SECTION_PARAMETERS_FAIL"

/*Get SECTION TYPES */
export const GET_SECTION_TYPES = "GET_SECTION_TYPES"
export const GET_SECTION_TYPES_SUCCESS = "GET_SECTION_TYPES_SUCCESS"
export const GET_SECTION_TYPES_FAIL = "GET_SECTION_TYPES_FAIL"

/*Get DISPATCH */
export const GET_DISPATCHES = "GET_DISPATCHES"
export const GET_DISPATCHES_SUCCESS = "GET_DISPATCHES_SUCCESS"
export const GET_DISPATCHES_FAIL = "GET_DISPATCHES_FAIL"

/**
 * Get DISPATCH DETAILS
 */
export const GET_DISPATCH_DETAIL = "GET_DISPATCH_DETAIL"
export const GET_DISPATCH_DETAIL_SUCCESS = "GET_DISPATCH_DETAIL_SUCCESS"
export const GET_DISPATCH_DETAIL_FAIL = "GET_DISPATCH_DETAIL_FAIL"

/**
 * Delete DISPATCH
 */
export const DELETE_DISPATCH = "DELETE_DISPATCH"
export const DELETE_DISPATCH_SUCCESS = "DELETE_DISPATCH_SUCCESS"
export const DELETE_DISPATCH_FAIL = "DELETE_DISPATCH_FAIL"

/**
 * Add DISPATCH
 */
export const ADD_NEW_DISPATCH = "ADD_NEW_DISPATCH"
export const ADD_DISPATCH_SUCCESS = "ADD_DISPATCH_SUCCESS"
export const ADD_DISPATCH_FAIL = "ADD_DISPATCH_FAIL"

/**
 * Update DISPATCH
 */
export const UPDATE_DISPATCH = "UPDATE_DISPATCH"
export const UPDATE_DISPATCH_SUCCESS = "UPDATE_DISPATCH_SUCCESS"
export const UPDATE_DISPATCH_FAIL = "UPDATE_DISPATCH_FAIL"
export const RESET_DISPATCHED = "RESET_DISPATCHED"

/*Get COMPANY */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

/**
 * Get COMPANY DETAILS
 */
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

/**
 * Delete COMPANY
 */
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

/**
 * Add COMPANY
 */
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

/**
 * Update COMPANY
 */
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

/**
 * Update TESTREPORT INVOICE
 */
export const UPDATE_TESTREPORT_INVOICE = "UPDATE_TESTREPORT_INVOICE"
export const UPDATE_TESTREPORT_INVOICE_SUCCESS =
  "UPDATE_TESTREPORT_INVOICE_SUCCESS"
export const UPDATE_TESTREPORT_INVOICE_FAIL = "UPDATE_TESTREPORT_INVOICE_FAIL"

//Reset Collection
export const RESET_COLLECTION = "RESET_COLLECTION"

/**
 * Get SOURCES
 */
export const GET_SOURCES = "GET_SOURCES"
export const GET_SOURCES_SUCCESS = "GET_SOURCES_SUCCESS"
export const GET_SOURCES_FAIL = "GET_SOURCES_FAIL"

/**
 * Get FUELS
 */
export const GET_FUELS = "GET_FUELS"
export const GET_FUELS_SUCCESS = "GET_FUELS_SUCCESS"
export const GET_FUELS_FAIL = "GET_FUELS_FAIL"

/**
 * Get CONSUMPTIONS
 */
export const GET_CONSUMPTIONS = "GET_CONSUMPTIONS"
export const GET_CONSUMPTIONS_SUCCESS = "GET_CONSUMPTIONS_SUCCESS"
export const GET_CONSUMPTIONS_FAIL = "GET_CONSUMPTIONS_FAIL"

/**
 * Get PROTOCOLS
 */
export const GET_PROTOCOLS = "GET_PROTOCOLS"
export const GET_PROTOCOLS_SUCCESS = "GET_PROTOCOLS_SUCCESS"
export const GET_PROTOCOLS_FAIL = "GET_PROTOCOLS_FAIL"

/**
 * Get UNITS
 */
export const GET_UNITS = "GET_UNITS"
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS"
export const GET_UNITS_FAIL = "GET_UNITS_FAIL"

/**
 * Get STANDARDLIMITS
 */
export const GET_STANDARDLIMITS = "GET_STANDARDLIMITS"
export const GET_STANDARDLIMITS_SUCCESS = "GET_STANDARDLIMITS_SUCCESS"
export const GET_STANDARDLIMITS_FAIL = "GET_STANDARDLIMITS_FAIL"

/**
 * Get APCS
 */
export const GET_APCS = "GET_APCS"
export const GET_APCS_SUCCESS = "GET_APCS_SUCCESS"
export const GET_APCS_FAIL = "GET_APCS_FAIL"

/**
 * Get DG_TYPES
 */
export const GET_DG_TYPES = "GET_DG_TYPES"
export const GET_DG_TYPES_SUCCESS = "GET_DG_TYPES_SUCCESS"
export const GET_DG_TYPES_FAIL = "GET_DG_TYPES_FAIL"

/**
 * Get DG_STANDARDLIMITS
 */
export const GET_DG_STANDARDLIMITS = "GET_DG_STANDARDLIMITS"
export const GET_DG_STANDARDLIMITS_SUCCESS = "GET_DG_STANDARDLIMITS_SUCCESS"
export const GET_DG_STANDARDLIMITS_FAIL = "GET_DG_STANDARDLIMITS_FAIL"

/**
 * Get DG_VISIBILITY
 */
export const GET_DG_VISIBILITY = "GET_DG_VISIBILITY"
export const GET_DG_VISIBILITY_SUCCESS = "GET_DG_VISIBILITY_SUCCESS"
export const GET_DG_VISIBILITY_FAIL = "GET_DG_VISIBILITY_FAIL"

import axios from "axios"
import { getAccessToken } from "./token_helper"

//apply base url for axios
const API_URL = `${process.env.REACT_APP_WEB_API_URL}`

const axiosApi = axios.create({
  baseURL: API_URL,
})

//axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.request.use(
  req => {
    if (getAccessToken()) {
      req.headers["Authorization"] = `Bearer ${getAccessToken()}`
    }
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

axiosApi.interceptors.response.use(
  response => response,
  err => {
    if (err.response && err.response.status === 401) {
      // Log out the user
      logoutUser()
    }
    // Pass the error along to the calling code
    return Promise.reject(err.response)
  }
)

async function logoutUser() {
  try {
    localStorage.removeItem("authUser")
    delete axios.defaults.headers.common.Authorization

    // Redirect to login page or perform any other logout logic
    window.location.replace("/login")
  } catch (error) {
    console.error("Logout failed:", error)
    // Handle error, possibly show an error message to the user
  }
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config) {
  return axiosApi.post(url, data, config).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewCollection as onAddNewCollection,
  updateCollection as onUpdateCollection,
  getCollectionDetail as onGetCollectionDetail,
  getSampleTypes as onGetSampleTypes,
  getSampleStatuses as onGetSampleStatuses,
  getParameterTypes as onGetParameterTypes,
  getCompanies as onGetCompanies,
  getCollectionParameters as onGetCollectionParameters,
  getSources as onGetSources,
  getFuels as onGetFuels,
  getConsumptions as onGetConsumptions,
  getProtocols as onGetProtocols,
  getUnits as onGetUnits,
  getStandardLimits as onGetStandardLimits,
  getAPCS as onGetAPCS,
  getDGtypes as onGetDGtypes,
  getDGStandardLimits as onGetDGStandardLimits,
  getDGVisibility as onGetDGVisibility,
} from "store/actions"
import { chain, isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { RESET_COLLECTION } from "store/sample/actionTypes"

function PrintCollection() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [types, setTypes] = useState(false)
  const [sourceID, setSourceID] = useState(0)
  const [sourceTypeID, setSourceTypeID] = useState(0)
  const [sampleTypeID, setSampleTypeID] = useState(0)
  const { id } = routeParams
  let counter = 0
  let index = 0

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Companies: yup.object().required("Required"),
    SampleTypes: yup.object().required("Required"),
    CollectionParameters: yup.array().of(
      yup.object().shape({
        ParameterID: yup.number(),
        Parameter: yup.string(),
        ParameterTypeID: yup.number(),
        Linked: yup.bool().default(false),
      })
    ),
    SampleDate: yup.string().required("Required"),
    SampleQuantity: yup.number().min(1).required("Required"),
    IssuedTo: yup.string().required("Required"),
    IssuedDate: yup.string().required("Required"),
    ReceivedBy: yup.string().required("Required"),
    SampleStatuses: yup.object().required("Required"),
    VerifiedBy: yup.string().required("Required"),
    Remark: yup.string(),
    Active: yup.bool(),
  })

  const formdefault = {
    IssuedTo: "",
    IssuedDate: "",
    SampleQuantity: 1,
    ReceivedBy: "",
    VerifiedBy: "",
    SampleDate: "",
    Remark: "",
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger, watch } =
    methods

  const { fields, append, remove } = useFieldArray({
    name: "CollectionParameters",
    control,
  })

  const { collection, collectionparameters, sources, loading, dgvisibility } =
    useSelector(state => state.sample)

  useDeepCompareEffect(() => {
    dispatch(onGetCollectionDetail(id))

    if (!isEmpty(collection)) {
      reset({ ...collection, CollectionParameters: collectionparameters })
    }
  }, [dispatch, id, collection])

  const uniqueKeyValuePairs = collectionparameters
    .filter(x => x.Linked === true)
    .reduce((acc, obj) => {
      const { ParameterTypeID, ParameterType } = obj
      const key = `${ParameterTypeID}_${ParameterType}`
      if (!acc[key]) {
        acc[key] = { ParameterTypeID, ParameterType }
      }
      return acc
    }, {})

  useEffect(() => {
    if (!isEmpty(collectionparameters)) {
      // Trigger a re-render by updating the form state
      setValue("CollectionParameters", collectionparameters)
    }
  }, [collectionparameters])

  useEffect(() => {
    if (sampleTypeID > 0) {
      dispatch(
        onGetCollectionParameters(
          sampleTypeID,
          collection.SampleCollectionID,
          0
        )
      )

      //Stack Emission / Source Emission
      // if (sampleTypeID === 8) {
      //   dispatch(onGetSources(sampleTypeID))
      // }
    }
  }, [sampleTypeID])

  // useEffect(() => {
  //   if (sourceID > 0 && sampleTypeID > 0) {
  //     let source = sources.filter(x => x.SourceID === sourceID)
  //     setSourceTypeID(source[0]?.SourceTypeID)
  //   }
  // }, [sourceID, sampleTypeID, sources])

  useEffect(() => {
    if (!isEmpty(collection)) {
      dispatch(onGetDGVisibility(collection.SampleCollectionID))
      setSampleTypeID(collection.SampleTypeID)
      setSourceTypeID(collection.SourceTypeID)
    }
  }, [collection])

  const onCancelClick = () => {
    navigate("/collections")
  }

  const divToPrint = useRef(null)
  const onPrintClick = () => {
    const printableArea = divToPrint.current

    if (printableArea) {
      window.print()
    }
  }

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_COLLECTION })
    }
  }, [])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  //console.log(dgvisibility)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Collections"
            breadcrumbItem="Sample Collection Details"
          />
          {routeParams.id !== "new" && isEmpty(collection) ? (
            <EmptyContainer
              backURL="/collections"
              message="There is no such collection!"
              linkText="Go to Colletions Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormProvider {...methods}>
                      <div ref={divToPrint} className="A4-print">
                        <p className="text-success">
                          <strong>
                            Sample Plan ID - {collection.SampleCode}
                          </strong>
                        </p>
                        <div className="row">
                          <div className="col-lg-4">
                            <p className="mb-0 pb-0 ps-0">
                              Customer/Company Name -{" "}
                              <strong>{collection.CompanyName}</strong>
                            </p>
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Issued To -{" "}
                                  <strong>{collection.IssuedTo}</strong>
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Issued Date -{" "}
                                  <strong>{collection.IssuedDate}</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Received By -{" "}
                                  <strong>{collection.ReceivedBy}</strong>
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Verified By -{" "}
                                  <strong>{collection.VerifiedBy}</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Environmental Temperature at the time of
                                  Sampling -{" "}
                                  <strong>
                                    {collection.SampleConditionSampling}
                                  </strong>
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Sample Location -{" "}
                                  <strong>{collection.SampleLocation}</strong>
                                </p>
                              </div>
                            </div>
                            {(sampleTypeID === 7 || sampleTypeID === 16) && (
                              <>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Ambient Temperature° C -
                                      <strong>
                                        {collection.AmbientTemperature}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Weather Condition -
                                      <strong>
                                        {collection.WeatherCondition}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Relative Humidity % -
                                      <strong>
                                        {collection.RelativeHumidity}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Indoor Temperature° C -
                                      <strong>
                                        {collection.IndoorTemperature}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Wind Direction -
                                      <strong>
                                        {collection.WindDirection}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {sampleTypeID === 8 && (
                              <div className="row">
                                <div className="col-lg-6">
                                  <p className="mb-0 pb-0 ps-0">
                                    Source Capacity -{" "}
                                    <strong>{collection.SourceCapacity}</strong>
                                  </p>
                                </div>
                                <div className="col-lg-6">
                                  <p className="mb-0 pb-0 ps-0">
                                    Consumption of Fuels
                                    <strong>
                                      {collection.ConsumptionOfFuels}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className="row" hidden={sampleTypeID !== 8}>
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Stack/Source -{" "}
                                  <strong>{collection.Source}</strong>
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Fuel - <strong>{collection.Fuel}</strong>
                                </p>
                              </div>
                            </div>
                            {sampleTypeID === 8 && sourceTypeID === 1 ? (
                              <div className="row">
                                <div className="col-lg-6">
                                  <p className="mb-0 pb-0 ps-0">
                                    Consumption -{" "}
                                    <strong>
                                      {collection.ConsumptionValue}
                                      {collection.Consumption}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Sample Type -{" "}
                                  <strong>{collection.SampleType}</strong>
                                </p>
                              </div>

                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Sample Date -{" "}
                                  <strong>{collection.SampleDate}</strong>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Active -{" "}
                                  <strong>
                                    {collection.Active ? "Yes" : "No"}
                                  </strong>
                                </p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Sample Status -{" "}
                                  <strong>{collection.SampleStatus}</strong>
                                </p>
                              </div>
                            </div>
                            {sampleTypeID === 8 && (
                              <>
                                <div className="col-lg-6">
                                  <p className="mb-0 pb-0 ps-0">
                                    Stack Temperature -
                                    <strong>
                                      {collection.StackTemperature}
                                    </strong>
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-lg-4">
                            <div className="row">
                              <div className="col-lg-6">
                                <p className="mb-0 pb-0 ps-0">
                                  Sampling Protocol -
                                  <strong>{collection.SamplingProtocol}</strong>
                                </p>
                              </div>
                            </div>
                            {sampleTypeID === 8 && (
                              <>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Average velocity [m/sec.] -
                                      <strong>
                                        {collection.AverageVelocity}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Sampling Duration -
                                      <strong>
                                        {collection.SamplingDuration}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Current Load -
                                      <strong>{collection.CurrentLoad}</strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Stack Diameter (at Port hole) -
                                      <strong>
                                        {collection.StackDiameter}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                            {sampleTypeID === 8 && (
                              <>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Attached APCS-
                                      <strong>{collection.SourceAPCS}</strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <p className="mb-0 pb-0 ps-0">
                                    Stack height (from Roof level) -
                                    <strong>
                                      {collection.StackHeightRoofLevel}
                                    </strong>
                                  </p>
                                </div>
                                <div className="row"></div>
                              </>
                            )}
                          </div>
                          <div className="col-lg-4">
                            <p className="mb-0 pb-0 ps-0">
                              Remark - <strong>{collection.Remark}</strong>
                            </p>
                            {sampleTypeID === 8 && (
                              <>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Sampling Height -{" "}
                                      <strong>
                                        {collection.SamplingHeight}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Type of Fuels -{" "}
                                      <strong>{collection.TypeOfFuels}</strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Flow Rate of SPM [LPM] -{" "}
                                      <strong>{collection.FlowRate}</strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Stack Height (from Ground level) -{" "}
                                      <strong>
                                        {collection.StackHeightGroundLevel}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Flow Rate of Gases [LPM] -
                                      <strong>
                                        {collection.FlowRateOfGases}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Duration of Monitoring -
                                      <strong>
                                        {collection.MonitoringDuration}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Flew gas temperature. (°C) -{" "}
                                      <strong>
                                        {collection.FlewGasTemperature}
                                      </strong>
                                    </p>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Quantity of Emission (m3/Hrs.) -{" "}
                                      <strong>
                                        {collection.QuantityOfEmission}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <p className="mb-0 pb-0 ps-0">
                                      Quantity of Emission (m3/Hrs.)-
                                      <strong>
                                        {collection.QuantityOfEmission}
                                      </strong>
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <table
                          style={{ marginTop: 15 }}
                          className="table table-bordered"
                        >
                          <thead>
                            <tr className="bg-secondary-subtle fw-bold">
                              <td
                                className="p-0 text-center border-start-0"
                                style={{
                                  width: "10px",
                                }}
                              >
                                S.No
                              </td>
                              <td
                                className="p-0 text-center"
                                style={{
                                  width: "220px",
                                }}
                              >
                                Parameters
                              </td>
                              <td
                                className="p-0 text-center"
                                style={{
                                  width: "220px",
                                }}
                              >
                                Protocol
                              </td>
                              <td
                                className="p-0 text-center"
                                style={{
                                  width: "220px",
                                }}
                              >
                                Unit
                              </td>
                              {sourceTypeID === 1 && (
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >
                                  Standard Limit
                                </td>
                              )}
                              {sourceTypeID === 2 && (
                                <>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGOne}
                                  >
                                    {`NEW Standred limit DG SET < 19KW`}
                                  </td>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGTwo}
                                  >
                                    {" "}
                                    {`NEW Standred limit DG SET < 75KW`}
                                  </td>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGThree}
                                  >{`NEW Standred limit DG SET < 800KW `}</td>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGFour}
                                  >{`Standred limit DG SET < 19KW(23.75KVA)`}</td>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGFive}
                                  >{`Standred limit DG SET < 75KW(93.75KVA)`}</td>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGSix}
                                  >
                                    {" "}
                                    {`Standred limit DG SET < 800KW(1000KVA) `}
                                  </td>
                                  <td
                                    className="p-0 text-center"
                                    style={{
                                      width: "220px",
                                    }}
                                    hidden={!dgvisibility.DGSeven}
                                  >{`Standred limit DG SET > 1000KVA `}</td>
                                </>
                              )}
                            </tr>
                          </thead>
                          {!isEmpty(collectionparameters) &&
                            Object.values(uniqueKeyValuePairs).map((pr, i) => (
                              <>
                                <tr key={"parent" + i}>
                                  <td
                                    colSpan={sourceTypeID === 1 ? 6 : 12}
                                    style={{
                                      padding: 5,
                                      fontWeight: "700",
                                      textAlign: "center",
                                    }}
                                  >
                                    {pr.ParameterType}
                                  </td>
                                </tr>
                                {fields &&
                                  fields
                                    .filter(
                                      x =>
                                        x.ParameterTypeID ===
                                          pr.ParameterTypeID && x.Linked == true
                                    )
                                    .map((ch, j) => {
                                      return (
                                        <>
                                          <tr key={"child" + ch.ParameterID}>
                                            <td>{(counter = counter + 1)}</td>
                                            <td
                                              dangerouslySetInnerHTML={{
                                                __html: ch.Parameter,
                                              }}
                                            ></td>
                                            <td>
                                              {sampleTypeID === 8
                                                ? ch.SourceProtocol
                                                : ch.TestMethod}
                                            </td>
                                            <td
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  sampleTypeID === 8
                                                    ? ch.SourceUnit
                                                    : ch.Unit,
                                              }}
                                            ></td>
                                            {sourceTypeID === 1 && (
                                              <td>{ch.SourceStandardLimit}</td>
                                            )}
                                            {sourceTypeID === 2 && (
                                              <>
                                                <td
                                                  hidden={!dgvisibility.DGOne}
                                                >
                                                  {ch.SourceDGOne}
                                                </td>
                                                <td
                                                  hidden={!dgvisibility.DGTwo}
                                                >
                                                  {ch.SourceDGTwo}
                                                </td>
                                                <td
                                                  hidden={!dgvisibility.DGThree}
                                                >
                                                  {ch.SourceDGThree}
                                                </td>
                                                <td
                                                  hidden={!dgvisibility.DGFour}
                                                >
                                                  {ch.SourceDGFour}
                                                </td>
                                                <td
                                                  hidden={!dgvisibility.DGFive}
                                                >
                                                  {ch.SourceDGFive}
                                                </td>
                                                <td
                                                  hidden={!dgvisibility.DGSix}
                                                >
                                                  {ch.SourceDGSix}
                                                </td>
                                                <td
                                                  hidden={!dgvisibility.DGSeven}
                                                >
                                                  {ch.SourceDGSeven}
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                          <span hidden={true}>
                                            {(index = index + 1)}
                                          </span>
                                        </>
                                      )
                                    })}
                              </>
                            ))}
                        </table>
                      </div>
                      <div className="d-flex flex-wrap gap-2 no-print">
                        <button
                          onClick={onPrintClick}
                          className="btn btn-success"
                        >
                          Print
                        </button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default PrintCollection

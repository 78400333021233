import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import Moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getCollections as onGetCollections,
  deleteCollection as onDeleteCollection,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

function SampleCollectionReport() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { collections, loading } = useSelector(state => state.sample)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [collection, setCollection] = useState(null)

  useEffect(() => {
    if (collections && !collections.length) {
      dispatch(onGetCollections())
    }
  }, [dispatch, collections])

  const handleAddClick = () => {
    navigate("/collection/new")
  }

  const onClickDelete = arg => {
    setCollection(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (collection && collection.CollectionID) {
      dispatch(onDeleteCollection(collection.CollectionID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const CollectionStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "CompanyName",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.CompanyName}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Sample Plan ID",
        accessor: "SamplePlanID",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Sample Type",
        accessor: "SampleType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "Active",
        Cell: cellProps => {
          return <CollectionStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={
                  "/generate-collection-report/" + cellProps.row.original.RowID
                }
                className="text-success"
              >
                Generate Report
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Collections"
            breadcrumbItem="Sample Collection List"
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={collections}
                      isGlobalFilter={true}
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={true}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
SampleCollectionReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SampleCollectionReport

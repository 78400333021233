import React, { useEffect, useMemo, useState } from "react"
import PropTypes, { func } from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import Moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getStaffs as onGetStaffs,
  deleteStaff as onDeleteStaff,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import { getUserImagePath } from "helpers/imageService"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

function Staff() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { staffs, loading } = useSelector(state => state.staff)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [staff, setStaff] = useState(null)

  useEffect(() => {
    if (staffs && !staffs.length) {
      dispatch(onGetStaffs())
    }
  }, [dispatch, staffs])

  const handleAddClick = () => {
    navigate("/user/new")
  }

  const onClickDelete = arg => {
    setStaff(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (staff && staff.UserID) {
      dispatch(onDeleteStaff(staff.UserID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = Moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const StaffStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.Name}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                {cellProps.row.original.RoleName}
              </p>
            </>
          )
        },
      },
      {
        Header: "Email",
        accessor: "Email",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <StaffStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/user/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Users" breadcrumbItem="User List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={staffs}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add user"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={true}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Staff.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Staff

import {
  GET_NAVIGATION_SIDEBAR,
  GET_NAVIGATION_SIDEBAR_SUCCESS,
  GET_NAVIGATION_SIDEBAR_FAIL,
} from "./actionTypes"

export const getNavigationSidebar = roleId => ({
  type: GET_NAVIGATION_SIDEBAR,
  roleId,
})

export const getNavigationSidebarSuccess = navigations => ({
  type: GET_NAVIGATION_SIDEBAR_SUCCESS,
  payload: navigations,
})

export const getNavigationSidebarFail = error => ({
  type: GET_NAVIGATION_SIDEBAR_FAIL,
  payload: error,
})

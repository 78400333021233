import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//Staff Pages
import Staffs from "../pages/Staff/index"
import StaffAddEdit from "../pages/Staff/StaffAddEdit"

//Companies
import Company from "../pages/Forms/company/index"
import CompanyAddEdit from "../pages/Forms/company/CompanyAddEdit"

//Form
import Collection from "../pages/Forms/collection/index"
import SampleCollection from "../pages/Forms/collection/SampleCollection"
import PrintCollection from "../pages/Forms/collection/PrintCollection"

import Booking from "../pages/Forms/booking/index"
import SampleBooking from "../pages/Forms/booking/SampleBooking"

import Section from "../pages/Forms/section/index"
import SampleSection from "../pages/Forms/section/SampleSection"

import Dispatched from "../pages/Forms/dispatch/index"
import SampleDispatch from "../pages/Forms/dispatch/SampleDispatch"

import SampleCollectionReport from "../pages/Forms/report/index"
import GenerateSampleCollectionReport from "../pages/Forms/report/GenerateSampleCollectionReport"

import AccountReport from "../pages/Forms/account-report/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//404 Page
import Pages404 from "../pages/Utility/404"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //Staff
  { path: "/users", component: <Staffs /> },
  { path: "/user/:id", component: <StaffAddEdit /> },

  //Company
  { path: "/companies", component: <Company /> },
  { path: "/company/:id", component: <CompanyAddEdit /> },

  //Collection
  { path: "/collections", component: <Collection /> },
  { path: "/collection/:id", component: <SampleCollection /> },
  { path: "/print-collection/:id", component: <PrintCollection /> },

  //Booking
  { path: "/bookings", component: <Booking /> },
  { path: "/booking/:id", component: <SampleBooking /> },

  //Section
  { path: "/sections", component: <Section /> },
  { path: "/section/:id", component: <SampleSection /> },

  //Dispatch
  { path: "/dispatches", component: <Dispatched /> },
  { path: "/dispatch/:id", component: <SampleDispatch /> },

  //Dispatch
  { path: "/account-report", component: <AccountReport /> },

  { path: "/collection-report", component: <SampleCollectionReport /> },
  {
    path: "/generate-collection-report/:id",
    component: <GenerateSampleCollectionReport />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  //404 Page
  { path: "*", component: <Pages404 /> },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }

import {
  GET_COLLECTIONS_FAIL,
  GET_COLLECTIONS_SUCCESS,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAIL,
  ADD_COLLECTION_FAIL,
  ADD_COLLECTION_SUCCESS,
  GET_COLLECTION_DETAIL_SUCCESS,
  GET_COLLECTION_DETAIL_FAIL,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAIL,
  GET_COLLECTION_PARAMETERS_SUCCESS,
  GET_COLLECTION_PARAMETERS_FAIL,
  GET_SAMPLE_TYPES_SUCCESS,
  GET_SAMPLE_TYPES_FAIL,
  GET_TEST_REPORT_SUCCESS,
  GET_TEST_REPORT_FAIL,
  UPDATE_TEST_REPORT_SUCCESS,
  UPDATE_TEST_REPORT_FAIL,
  GET_SAMPLE_STATUSES_SUCCESS,
  GET_SAMPLE_STATUSES_FAIL,
  GET_PARAMETER_TYPES_SUCCESS,
  GET_PARAMETER_TYPES_FAIL,
  GET_PARAMETERS_SUCCESS,
  GET_PARAMETERS_FAIL,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAIL,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  GET_BOOKING_DETAIL_SUCCESS,
  GET_BOOKING_DETAIL_FAIL,
  GET_SAMPLE_DETAIL_SUCCESS,
  GET_SAMPLE_DETAIL_FAIL,
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_FAIL,
  GET_SECTION_DETAIL_SUCCESS,
  GET_SECTION_DETAIL_FAIL,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAIL,
  ADD_SECTION_SUCCESS,
  ADD_SECTION_FAIL,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAIL,
  GET_SECTION_TYPES_SUCCESS,
  GET_SECTION_TYPES_FAIL,
  GET_SECTION_PARAMETERS_SUCCESS,
  GET_SECTION_PARAMETERS_FAIL,
  GET_DISPATCHES_SUCCESS,
  GET_DISPATCHES_FAIL,
  GET_DISPATCH_DETAIL_SUCCESS,
  GET_DISPATCH_DETAIL_FAIL,
  DELETE_DISPATCH_SUCCESS,
  DELETE_DISPATCH_FAIL,
  ADD_DISPATCH_SUCCESS,
  ADD_DISPATCH_FAIL,
  UPDATE_DISPATCH_SUCCESS,
  UPDATE_DISPATCH_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  UPDATE_TESTREPORT_INVOICE_SUCCESS,
  UPDATE_TESTREPORT_INVOICE_FAIL,
  RESET_COLLECTION,
  GET_SOURCES_SUCCESS,
  GET_SOURCES_FAIL,
  GET_FUELS_SUCCESS,
  GET_FUELS_FAIL,
  GET_CONSUMPTIONS_SUCCESS,
  GET_CONSUMPTIONS_FAIL,
  GET_PROTOCOLS_SUCCESS,
  GET_PROTOCOLS_FAIL,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  GET_STANDARDLIMITS_SUCCESS,
  GET_STANDARDLIMITS_FAIL,
  GET_APCS_SUCCESS,
  GET_APCS_FAIL,
  GET_DG_TYPES_SUCCESS,
  GET_DG_TYPES_FAIL,
  GET_DG_STANDARDLIMITS_SUCCESS,
  GET_DG_STANDARDLIMITS_FAIL,
  GET_DG_VISIBILITY_SUCCESS,
  GET_DG_VISIBILITY_FAIL,
  RESET_DISPATCHED,
} from "./actionTypes"

const INIT_STATE = {
  collections: [],
  collection: {},
  collectionparameters: [],
  sampletypes: [],
  parameters: [],
  testreport: {},
  testreportdetails: [],
  samplestatuses: [],
  parametertypes: [],
  bookings: [],
  booking: {},
  sections: [],
  section: {},
  sectiontypes: [],
  sectionparameters: [],
  dispatches: [],
  dispatched: {},
  companies: [],
  company: {},
  sources: [],
  fuels: [],
  consumptions: [],
  protocols: [],
  units: [],
  standardlimits: [],
  apcs: [],
  dgtypes: [],
  dgstandardlimits: [],
  dgvisibility: {},
  error: {},
  loading: true,
}

const Sample = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: action.payload,
        loading: true,
      }
    case GET_COLLECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_COLLECTION_SUCCESS: {
      return {
        ...state,
        collections: state.collections.filter(
          collection => collection.SampleCollectionID !== action.payload
        ),
      }
    }
    case DELETE_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: action.payload,
      }
    case ADD_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        collection: action.payload,
        loading: true,
      }
    case GET_COLLECTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: action.payload,
      }
    case UPDATE_COLLECTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_TYPES_SUCCESS:
      return {
        ...state,
        sampletypes: action.payload,
      }
    case GET_SAMPLE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TEST_REPORT_SUCCESS:
      return {
        ...state,
        collection: action.payload.Collection,
        testreport: action.payload.TestReport,
        sectionparameters: action.payload.SectionParameters,
        dispatched: action.payload.Dispatched,
        dgvisibility: action.payload.DGVisibility,
        loading: true,
      }
    case GET_TEST_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_TEST_REPORT_SUCCESS:
      return {
        ...state,
        collection: action.payload.Collection,
        testreport: action.payload.TestReport,
        sectionparameters: action.payload.SectionParameters,
        dispatched: action.payload.Dispatched,
        dgvisibility: action.payload.DGVisibility,
      }
    case UPDATE_TEST_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_STATUSES_SUCCESS:
      return {
        ...state,
        samplestatuses: action.payload,
      }
    case GET_SAMPLE_STATUSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PARAMETER_TYPES_SUCCESS:
      return {
        ...state,
        parametertypes: action.payload,
      }
    case GET_PARAMETER_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PARAMETERS_SUCCESS:
      return {
        ...state,
        parameters: action.payload,
      }
    case GET_PARAMETERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
        loading: true,
      }
    case GET_BOOKINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_BOOKING_SUCCESS: {
      return {
        ...state,
        bookings: state.bookings.filter(
          b => b.SampleBookingID !== action.payload
        ),
      }
    }
    case DELETE_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
      }
    case ADD_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BOOKING_DETAIL_SUCCESS:
      return {
        ...state,
        booking: action.payload,
        loading: true,
      }

    case GET_BOOKING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: action.payload,
      }
    case UPDATE_BOOKING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_DETAIL_SUCCESS:
      return {
        ...state,
        collection: action.payload.Collection,
        booking: action.payload.Booking,
        section: action.payload.Section,
        dispatched: action.payload.Dispatch,
        loading: true,
      }

    case GET_SAMPLE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.payload,
        loading: true,
      }
    case GET_SECTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SECTION_DETAIL_SUCCESS:
      return {
        ...state,
        section: action.payload,
        loading: true,
      }

    case GET_SECTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_SECTION_SUCCESS: {
      return {
        ...state,
        sections: state.sections.filter(
          b => b.SampleSectionID !== action.payload
        ),
      }
    }
    case DELETE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_SECTION_SUCCESS:
      return {
        ...state,
        sections: action.payload,
      }
    case ADD_SECTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        sections: action.payload,
      }
    case UPDATE_SECTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SECTION_TYPES_SUCCESS:
      return {
        ...state,
        sectiontypes: action.payload,
      }
    case GET_SECTION_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DISPATCHES_SUCCESS:
      return {
        ...state,
        dispatches: action.payload,
        loading: true,
      }
    case GET_DISPATCHES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DISPATCH_DETAIL_SUCCESS:
      return {
        ...state,
        dispatched: action.payload,
        loading: true,
      }

    case GET_DISPATCH_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_DISPATCH_SUCCESS: {
      return {
        ...state,
        dispatches: state.sections.filter(
          b => b.SampleDispatchID !== action.payload
        ),
      }
    }
    case DELETE_DISPATCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_DISPATCH_SUCCESS:
      return {
        ...state,
        dispatches: action.payload,
      }
    case ADD_DISPATCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_DISPATCH_SUCCESS:
      return {
        ...state,
        dispatches: action.payload,
      }
    case UPDATE_DISPATCH_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload,
        loading: true,
      }
    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: true,
      }
    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: state.sections.filter(
          b => b.SampleDispatchID !== action.payload
        ),
      }
    }
    case DELETE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      }
    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      }
    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COLLECTION_PARAMETERS_SUCCESS:
      return {
        ...state,
        collectionparameters: action.payload,
      }
    case GET_COLLECTION_PARAMETERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SECTION_PARAMETERS_SUCCESS:
      return {
        ...state,
        sectionparameters: action.payload,
      }
    case GET_SECTION_PARAMETERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_TESTREPORT_INVOICE_SUCCESS:
      return {
        ...state,
        collection: action.payload.Collection,
        testreport: action.payload.TestReport,
        sectionparameters: action.payload.SectionParameters,
        dispatched: action.payload.Dispatched,
        dgvisibility: action.payload.DGVisibility,
      }
    case UPDATE_TESTREPORT_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_COLLECTION:
      return {
        ...state,
        collection: {},
        collectionparameters: [],
        sectiontypes: [],
        dgvisibility: {},
      }
    case RESET_DISPATCHED:
      return {
        ...state,
        section: {},
        dispatched: {},
      }
    case GET_SOURCES_SUCCESS:
      return {
        ...state,
        sources: action.payload,
        loading: true,
      }
    case GET_SOURCES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FUELS_SUCCESS:
      return {
        ...state,
        fuels: action.payload,
        loading: true,
      }
    case GET_FUELS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CONSUMPTIONS_SUCCESS:
      return {
        ...state,
        consumptions: action.payload,
        loading: true,
      }
    case GET_CONSUMPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROTOCOLS_SUCCESS:
      return {
        ...state,
        protocols: action.payload,
        loading: true,
      }
    case GET_PROTOCOLS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload,
        loading: true,
      }
    case GET_UNITS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STANDARDLIMITS_SUCCESS:
      return {
        ...state,
        standardlimits: action.payload,
        loading: true,
      }
    case GET_STANDARDLIMITS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_APCS_SUCCESS:
      return {
        ...state,
        apcs: action.payload,
        loading: true,
      }
    case GET_APCS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DG_TYPES_SUCCESS:
      return {
        ...state,
        dgtypes: action.payload,
        loading: true,
      }
    case GET_DG_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DG_STANDARDLIMITS_SUCCESS:
      return {
        ...state,
        dgstandardlimits: action.payload,
        loading: true,
      }
    case GET_DG_STANDARDLIMITS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DG_VISIBILITY_SUCCESS:
      return {
        ...state,
        dgvisibility: action.payload,
        loading: true,
      }
    case GET_DG_VISIBILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Sample

import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Collection Redux States
import {
  GET_COLLECTIONS,
  DELETE_COLLECTION,
  ADD_NEW_COLLECTION,
  GET_COLLECTION_DETAIL,
  UPDATE_COLLECTION,
  GET_COLLECTION_PARAMETERS,
  GET_SAMPLE_TYPES,
  GET_TEST_REPORT,
  UPDATE_TEST_REPORT,
  GET_SAMPLE_STATUSES,
  GET_PARAMETER_TYPES,
  GET_PARAMETERS,
  GET_BOOKINGS,
  ADD_NEW_BOOKING,
  DELETE_BOOKING,
  GET_BOOKING_DETAIL,
  UPDATE_BOOKING,
  GET_SAMPLE_DETAIL,
  GET_SECTIONS,
  GET_SECTION_DETAIL,
  ADD_NEW_SECTION,
  DELETE_SECTION,
  UPDATE_SECTION,
  GET_SECTION_TYPES,
  GET_SECTION_PARAMETERS,
  GET_DISPATCHES,
  GET_DISPATCH_DETAIL,
  ADD_NEW_DISPATCH,
  DELETE_DISPATCH,
  UPDATE_DISPATCH,
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  ADD_NEW_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
  UPDATE_TESTREPORT_INVOICE,
  GET_SOURCES,
  GET_FUELS,
  GET_CONSUMPTIONS,
  GET_PROTOCOLS,
  GET_UNITS,
  GET_STANDARDLIMITS,
  GET_APCS,
  GET_DG_TYPES,
  GET_DG_STANDARDLIMITS,
  GET_DG_VISIBILITY,
} from "./actionTypes"
import {
  getCollectionsFail,
  getCollectionsSuccess,
  deleteCollectionSuccess,
  deleteCollectionFail,
  addCollectionSuccess,
  addCollectionFail,
  getCollectionDetailSuccess,
  getCollectionDetailFail,
  updateCollectionSuccess,
  updateCollectionFail,
  getCollectionParametersSuccess,
  getCollectionParametersFail,
  getSampleTypesSuccess,
  getSampleTypesFail,
  getTestReportSuccess,
  getTestReportFail,
  updateTestReportSuccess,
  updateTestReportFail,
  getSampleStatusesSuccess,
  getSampleStatusesFail,
  getParameterTypesSuccess,
  getParameterTypesFail,
  getParametersSuccess,
  getParametersFail,
  getBookingsSuccess,
  getBookingsFail,
  getBookingDetailSuccess,
  getBookingDetailFail,
  addBookingSuccess,
  addBookingFail,
  deleteBookingSuccess,
  deleteBookingFail,
  updateBookingSuccess,
  updateBookingFail,
  getSampleDetailSuccess,
  getSampleDetailFail,
  getSectionsSuccess,
  getSectionsFail,
  getSectionDetailSuccess,
  getSectionDetailFail,
  addSectionSuccess,
  addSectionFail,
  deleteSectionSuccess,
  deleteSectionFail,
  updateSectionSuccess,
  updateSectionFail,
  getSectionTypesSuccess,
  getSectionTypesFail,
  getSectionParametersSuccess,
  getSectionParametersFail,
  getDispatchesSuccess,
  getDispatchesFail,
  getDispatchDetailSuccess,
  getDispatchDetailFail,
  addDispatchSuccess,
  addDispatchFail,
  deleteDispatchSuccess,
  deleteDispatchFail,
  updateDispatchSuccess,
  updateDispatchFail,
  getCompaniesSuccess,
  getCompaniesFail,
  getCompanyDetailSuccess,
  getCompanyDetailFail,
  addCompanySuccess,
  addCompanyFail,
  deleteCompanySuccess,
  deleteCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  updateTestReportInvoiceSuccess,
  updateTestReportInvoiceFail,
  getSourcesSuccess,
  getSourcesFail,
  getFuelsSuccess,
  getFuelsFail,
  getConsumptionsSuccess,
  getConsumptionsFail,
  getProtocolsSuccess,
  getProtocolsFail,
  getUnitsSuccess,
  getUnitsFail,
  getStandardLimitsSuccess,
  getStandardLimitsFail,
  getAPCSSuccess,
  getAPCSFail,
  getDGtypesSuccess,
  getDGtypesFail,
  getDGStandardLimitsSuccess,
  getDGStandardLimitsFail,
  getDGVisibilitySuccess,
  getDGVisibilityFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCollections,
  deleteCollection,
  addNewCollection,
  getCollectionDetail,
  updateCollection,
  getCollectionParameters,
  getSampleTypes,
  getTestReport,
  saveTestReport,
  getSampleStatuses,
  getParameterTypes,
  getParameters,
  getBookings,
  deleteBooking,
  addNewBooking,
  getBookingDetail,
  updateBooking,
  getSampleDetail,
  getSections,
  getSectionDetail,
  deleteSection,
  addNewSection,
  updateSection,
  getSectionTypes,
  getSectionParameters,
  getDispatches,
  getDispatchDetail,
  deleteDispatch,
  addNewDispatch,
  updateDispatch,
  getCompanies,
  getCompanyDetail,
  deleteCompany,
  addNewCompany,
  updateCompany,
  updateTestReportInvoice,
  getSources,
  getFuels,
  getConsumptions,
  getProtocols,
  getUnits,
  getStandardLimits,
  getAPCS,
  getDGTypes,
  getDGStandardLimits,
  getDGVisibility,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCollections() {
  try {
    const response = yield call(getCollections)
    yield put(getCollectionsSuccess(response))
  } catch (error) {
    yield put(getCollectionsFail(error))
  }
}

function* onDeleteCollection({ payload: collection }) {
  try {
    const response = yield call(deleteCollection, collection)
    yield put(deleteCollectionSuccess(response))
    toast.success("Collection deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteCollectionFail(error))
    toast.error("Collection deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewCollection({ payload: collection }) {
  try {
    const response = yield call(addNewCollection, collection)
    yield put(addCollectionSuccess(response))
    toast.success("Collection added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addCollectionFail(error))
    toast.error("Collection addition failed", { autoClose: 2000 })
  }
}

const getParsedCollectionData = x => {
  // Function to handle transforming a single object
  const transformObject = result => ({
    ...result,
    ReceivedBy: result.ReceivedBy ?? "",
    VerifiedBy: result.VerifiedBy ?? "",
    Remark: result.Remark ?? "",
    AddressLine1: result.AddressLine1 ?? "",
    AddressLine2: result.AddressLine2 ?? "",
    City: result.City ?? "",
    State: result.State ?? "",
    IssuedTo: result.IssuedTo ?? "",
    Companies: {
      SampleCompanyID: result.CompanyID,
      CompanyName: result.CompanyName,
    },
    SampleTypes: {
      SampleTypeID: result.SampleTypeID,
      SampleType: result.SampleType,
    },
    SampleStatuses: {
      SampleStatusID: result.SampleStatusID,
      SampleStatus: result.SampleStatus,
    },
    Sources: { SourceID: result.SourceID, Source: result.Source },
    Fuels: { FuelID: result.FuelID, Fuel: result.Fuel },
    Consumptions: {
      ConsumptionID: result.ConsumptionID,
      Consumption: result.Consumption,
    },
    APCS: {
      SourceAPCSID: result.SourceAPCSID ?? "",
      SourceAPCS: result.SourceAPCS ?? "Select...",
    },
    SampleDate: result.SampleDate
      ? Moment(result.SampleDate).format("DD MMM, YYYY")
      : "",
    IssuedDate: result.IssuedDate
      ? Moment(result.IssuedDate).format("DD MMM, YYYY")
      : "",
  })

  if (Array.isArray(x)) {
    // If x is an array, map over it and transform each element
    return x.map(transformObject)
  } else {
    // If x is an object, transform it directly
    return transformObject(x)
  }
}

function* fetchCollectionDetail({ collectionId }) {
  try {
    const response = yield call(getCollectionDetail, collectionId)
    yield put(getCollectionDetailSuccess(getParsedCollectionData(response[0])))
  } catch (error) {
    yield put(getCollectionDetailFail(error))
  }
}

function* onUpdateCollection({ payload: collection }) {
  try {
    const response = yield call(updateCollection, collection)
    yield put(updateCollectionSuccess(getParsedCollectionData(response)))
    toast.success("Collection updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateCollectionFail(error))
    toast.error("Collection updation failed", { autoClose: 2000 })
  }
}

function* fetchDGVisibility({ payload: collectionId }) {
  try {
    const response = yield call(getDGVisibility, collectionId)
    yield put(getDGVisibilitySuccess(response))
  } catch (error) {
    yield put(getDGVisibilityFail(error))
  }
}

function* fetchSampleTypes() {
  try {
    const response = yield call(getSampleTypes)
    yield put(getSampleTypesSuccess(response))
  } catch (error) {
    yield put(getSampleTypesFail(error))
  }
}

function* fetchSampleStatuses() {
  try {
    const response = yield call(getSampleStatuses)
    yield put(getSampleStatusesSuccess(response))
  } catch (error) {
    yield put(getSampleStatusesFail(error))
  }
}

function* fetchParamterTypes() {
  try {
    const response = yield call(getParameterTypes)
    yield put(getParameterTypesSuccess(response))
  } catch (error) {
    yield put(getParameterTypesFail(error))
  }
}

function* fetchParamters() {
  try {
    const response = yield call(getParameters)
    yield put(getParametersSuccess(response))
  } catch (error) {
    yield put(getParametersFail(error))
  }
}

function* fetchTestReport({ payload: collectionId }) {
  try {
    let response = yield call(getTestReport, collectionId)

    let testReportDetail = response.TestReport
    testReportDetail = {
      ...testReportDetail,
      TestStartDate: testReportDetail.TestStartDate
        ? Moment(testReportDetail.TestStartDate).format("D-MM-yyyy")
        : "",
      TestEndDate: testReportDetail.TestEndDate
        ? Moment(testReportDetail.TestEndDate).format("D-MM-yyyy")
        : "",
      LTRIssueDate: testReportDetail.LTRIssueDate
        ? Moment(testReportDetail.LTRIssueDate).format("D-MM-yyyy")
        : "",
    }
    response.TestReport = testReportDetail

    yield put(getTestReportSuccess(response))
  } catch (error) {
    yield put(getTestReportFail(error))
  }
}

function* onUpdateTestReportDetail({ payload: { reportId, data } }) {
  try {
    const request = {
      reportId: reportId,
      data: data.report,
    }

    let response = yield call(saveTestReport, request)
    yield put(updateTestReportSuccess(response))
  } catch (error) {
    yield put(updateTestReportFail(error))
  }
}

function* fetchBookings() {
  try {
    const response = yield call(getBookings)
    yield put(getBookingsSuccess(response))
  } catch (error) {
    yield put(getBookingsFail(error))
  }
}

function* onDeleteBooking({ payload: bookingId }) {
  try {
    const response = yield call(deleteBooking, bookingId)
    yield put(deleteBookingSuccess(response))
    toast.success("Booking deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteBookingFail(error))
    toast.error("Booking deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewBooking({ payload: booking }) {
  try {
    const response = yield call(addNewBooking, {
      ...booking,
      SampleCollections: {
        SampleCollectionID: booking.SampleCollections.SampleCollectionID,
        SampleCode: booking.SampleCollections.SampleCode,
      },
    })
    yield put(addBookingSuccess(response))
    toast.success("Booking added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addBookingFail(error))
    toast.error("Booking addition failed", { autoClose: 2000 })
  }
}

const getParsedBookingDetail = x => {
  const transformObject = result => ({
    ...result,
    SectionTypes: JSON.parse(result.SectionTypeValues),
    SampleCollections: {
      SampleCollectionID: result.SampleCollectionID,
      SampleCode: result.SampleCode,
    },
    BookedBy: result.BookedBy ?? "",
    LTRIssueDate: result.LTRIssueDate
      ? Moment(result.LTRIssueDate).format("DD MMM, YYYY")
      : "",
  })

  if (Array.isArray(x)) {
    // If x is an array, map over it and transform each element
    return x.map(transformObject)
  } else {
    // If x is an object, transform it directly
    return transformObject(x)
  }
}

function* fetchBookingDetail({ bookingId }) {
  try {
    const response = yield call(getBookingDetail, bookingId)
    yield put(getBookingDetailSuccess(getParsedBookingDetail(response[0])))
  } catch (error) {
    yield put(getBookingDetailFail(error))
  }
}

function* onUpdateBooking({ payload: booking }) {
  try {
    const response = yield call(updateBooking, {
      ...booking,
      SampleCollections: {
        SampleCollectionID: booking.SampleCollections.SampleCollectionID,
        SampleCode: booking.SampleCollections.SampleCode,
      },
    })
    yield put(updateBookingSuccess(getParsedBookingDetail(response)))
    toast.success("Booking updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateBookingFail(error))
    toast.error("Booking updation failed", { autoClose: 2000 })
  }
}

function* fetchSectionTypes({ payload: sampleTypeId }) {
  try {
    const response = yield call(getSectionTypes, sampleTypeId)
    yield put(getSectionTypesSuccess(response))
  } catch (error) {
    yield put(getSectionTypesFail(error))
  }
}

function* fetchSections() {
  try {
    const response = yield call(getSections)
    //console.log(response)
    yield put(getSectionsSuccess(response))
  } catch (error) {
    yield put(getSectionsFail(error))
  }
}

function* onDeleteSection({ payload: sectionId }) {
  try {
    const response = yield call(deleteSection, sectionId)
    yield put(deleteSectionSuccess(response))
    toast.success("Section deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteSectionFail(error))
    toast.error("Section deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewSection({ payload: section }) {
  try {
    const response = yield call(addNewSection, section)
    yield put(addSectionSuccess(response))
    toast.success("Section added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addSectionFail(error))
    toast.error("Section addition failed", { autoClose: 2000 })
  }
}

const getParsedSectionDetail = x => {
  const transformObject = result => ({
    ...result,
    LTRReceivedBy: result.LTRReceivedBy ?? "",
    LTRReturnTo: result.LTRReturnTo ?? "",
    Remark: result.Remark ?? "",
    TestStartDate: result.TestStartDate
      ? Moment(result.TestStartDate).format("DD MMM, YYYY")
      : "",
    TestEndDate: result.TestEndDate
      ? Moment(result.TestEndDate).format("DD MMM, YYYY")
      : "",
    SampleDate: result.SampleDate
      ? Moment(result.SampleDate).format("DD MMM, YYYY")
      : "",
  })

  if (Array.isArray(x)) {
    // If x is an array, map over it and transform each element
    return x.map(transformObject)
  } else {
    // If x is an object, transform it directly
    return transformObject(x)
  }
}

function* fetchSectionDetail({ sectionId }) {
  try {
    const response = yield call(getSectionDetail, sectionId)
    yield put(getSectionDetailSuccess(getParsedSectionDetail(response[0])))
  } catch (error) {
    yield put(getSectionDetailFail(error))
  }
}

function* onUpdateSection({ payload: section }) {
  try {
    const response = yield call(updateSection, section)
    yield put(updateSectionSuccess(getParsedSectionDetail(response)))
    toast.success("Section updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateSectionFail(error))
    toast.error("Section updation failed", { autoClose: 2000 })
  }
}
function* fetchDispatches() {
  try {
    const response = yield call(getDispatches)
    yield put(getDispatchesSuccess(response))
  } catch (error) {
    yield put(getDispatchesFail(error))
  }
}

function* onDeleteDispatch({ payload: dispatchId }) {
  try {
    const response = yield call(deleteDispatch, dispatchId)
    yield put(deleteDispatchSuccess(response))
    toast.success("Dispatch deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteDispatchFail(error))
    toast.error("Dispatch deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewDispatch({ payload: dispatch }) {
  try {
    const response = yield call(addNewDispatch, dispatch)
    yield put(addDispatchSuccess(response))
    toast.success("Dispatch added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addDispatchFail(error))
    toast.error("Dispatch addition failed", { autoClose: 2000 })
  }
}

const getParsedDispatchDetail = x => {
  const transformObject = result => ({
    ...result,
    SampleDate: result.SampleDate
      ? Moment(result.SampleDate).format("DD MMM, YYYY")
      : "",
  })

  if (Array.isArray(x)) {
    // If x is an array, map over it and transform each element
    return x.map(transformObject)
  } else {
    // If x is an object, transform it directly
    return transformObject(x)
  }
}

function* fetchDispatchDetail({ dispatchId }) {
  try {
    const response = yield call(getDispatchDetail, dispatchId)
    yield put(getDispatchDetailSuccess(getParsedDispatchDetail(response[0])))
  } catch (error) {
    yield put(getDispatchDetailFail(error))
  }
}

function* onUpdateDispatch({ payload: dispatch }) {
  try {
    const response = yield call(updateDispatch, dispatch)
    yield put(updateDispatchSuccess(getParsedDispatchDetail(response)))
    toast.success("Dispatch updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateDispatchFail(error))
    toast.error("Dispatch updation failed", { autoClose: 2000 })
  }
}

function* fetchCompanies() {
  try {
    const response = yield call(getCompanies)
    yield put(getCompaniesSuccess(response))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}

function* onDeleteCompany({ payload: companyId }) {
  try {
    const response = yield call(deleteCompany, companyId)
    yield put(deleteCompanySuccess(response))
    toast.success("Company deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteCompanyFail(error))
    toast.error("Company deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewCompany({ payload: company }) {
  try {
    const response = yield call(addNewCompany, company)
    yield put(addCompanySuccess(response[0]))
    toast.success("Company added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addCompanyFail(error))
    toast.error("Company addition failed", { autoClose: 2000 })
  }
}

const getParsedCompanyData = result => {
  return {
    ...result,
  }
}

function* fetchCompanyDetail({ companyId }) {
  try {
    const response = yield call(getCompanyDetail, companyId)
    yield put(getCompanyDetailSuccess(getParsedCompanyData(response[0])))
  } catch (error) {
    yield put(getCompanyDetailFail(error))
  }
}

function* onUpdateCompany({ payload: company }) {
  try {
    const response = yield call(updateCompany, company)
    yield put(updateCompanySuccess(getParsedCompanyData(response[0])))
    toast.success("Company updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateCompanyFail(error))
    toast.error("Company updation failed", { autoClose: 2000 })
  }
}

function* fetchSampleDetail({ collectionId }) {
  try {
    const response = yield call(getSampleDetail, collectionId)

    response.Collection = getParsedCollectionData(response.Collection)
    response.Booking = getParsedBookingDetail(response.Booking)
    response.Section = getParsedSectionDetail(response.Section)

    yield put(getSampleDetailSuccess(response))
  } catch (error) {
    yield put(getSampleDetailFail(error))
  }
}

const getParsedCollectionParametersData = result => {
  return result.map(x => ({
    ...x,
    Protocols: {
      SourceProtocolID: x.SourceProtocolID ?? "",
      SourceProtocol: x.SourceProtocol ?? "Select...",
    },
    Units: {
      SourceUnitID: x.SourceUnitID ?? "",
      SourceUnit: x.SourceUnit ?? "Select...",
    },
    StandardLimits: {
      SourceStandardLimitID: x.SourceStandardLimitID ?? "",
      SourceStandardLimit: x.SourceStandardLimit ?? "Select...",
    },
    DGOne: {
      DGStandardLimitID: x.SourceDGOneID ?? "",
      DGStandardLimit: x.SourceDGOne ?? "Select...",
    },
    DGTwo: {
      DGStandardLimitID: x.SourceDGTwoID ?? "",
      DGStandardLimit: x.SourceDGTwo ?? "Select...",
    },
    DGThree: {
      DGStandardLimitID: x.SourceDGThreeID ?? "",
      DGStandardLimit: x.SourceDGThree ?? "Select...",
    },
    DGFour: {
      DGStandardLimitID: x.SourceDGFourID ?? "",
      DGStandardLimit: x.SourceDGFour ?? "Select...",
    },
    DGFive: {
      DGStandardLimitID: x.SourceDGFiveID ?? "",
      DGStandardLimit: x.SourceDGFive ?? "Select...",
    },
    DGSix: {
      DGStandardLimitID: x.SourceDGSixID ?? "",
      DGStandardLimit: x.SourceDGSix ?? "Select...",
    },
    DGSeven: {
      DGStandardLimitID: x.SourceDGSevenID ?? "",
      DGStandardLimit: x.SourceDGSeven ?? "Select...",
    },
  }))
}

function* fetchCollectionParameters({
  payload: { collectionId, sampleTypeId, sectionTypeId },
}) {
  try {
    const response = yield call(getCollectionParameters, {
      collectionId,
      sampleTypeId,
      sectionTypeId,
    })
    yield put(
      getCollectionParametersSuccess(
        getParsedCollectionParametersData(response)
      )
    )
  } catch (error) {
    yield put(getCollectionParametersFail(error))
  }
}

function* fetchSectionParameters({ payload: sectionId }) {
  try {
    const response = yield call(getSectionParameters, sectionId)
    yield put(getSectionParametersSuccess(response))
  } catch (error) {
    yield put(getSectionParametersFail(error))
  }
}

function* onUpdateTestReportInvoice({ payload: request }) {
  try {
    let response = yield call(updateTestReportInvoice, request)
    yield put(updateTestReportInvoiceSuccess(response))
  } catch (error) {
    yield put(updateTestReportInvoiceFail(error))
  }
}

function* fetchSources({ payload: sampleTypeId }) {
  try {
    const response = yield call(getSources, sampleTypeId)
    yield put(getSourcesSuccess(response))
  } catch (error) {
    yield put(getSourcesFail(error))
  }
}

function* fetchFuels({ payload: sourceTypeId }) {
  try {
    const response = yield call(getFuels, sourceTypeId)
    yield put(getFuelsSuccess(response))
  } catch (error) {
    yield put(getFuelsFail(error))
  }
}

function* fetchConsumptios({ payload: sourceTypeId }) {
  try {
    const response = yield call(getConsumptions, sourceTypeId)
    yield put(getConsumptionsSuccess(response))
  } catch (error) {
    yield put(getConsumptionsFail(error))
  }
}

function* fetchProtocols({ payload: sourceTypeId }) {
  try {
    const response = yield call(getProtocols, sourceTypeId)
    yield put(getProtocolsSuccess(response))
  } catch (error) {
    yield put(getProtocolsFail(error))
  }
}

function* fetchUnits({ payload: sourceTypeId }) {
  try {
    const response = yield call(getUnits, sourceTypeId)
    yield put(getUnitsSuccess(response))
  } catch (error) {
    yield put(getUnitsFail(error))
  }
}

function* fetchStandardLimits({ payload: sourceTypeId }) {
  try {
    const response = yield call(getStandardLimits, sourceTypeId)
    yield put(getStandardLimitsSuccess(response))
  } catch (error) {
    yield put(getStandardLimitsFail(error))
  }
}

function* fetchAPCS({ payload: sourceTypeId }) {
  try {
    const response = yield call(getAPCS, sourceTypeId)
    yield put(getAPCSSuccess(response))
  } catch (error) {
    yield put(getAPCSFail(error))
  }
}

function* fetchDGTyes() {
  try {
    const response = yield call(getDGTypes)
    yield put(getDGtypesSuccess(response))
  } catch (error) {
    yield put(getDGtypesFail(error))
  }
}

function* fetchDGStandardLimits() {
  try {
    const response = yield call(getDGStandardLimits)
    yield put(getDGStandardLimitsSuccess(response))
  } catch (error) {
    yield put(getDGStandardLimitsFail(error))
  }
}

function* sampleSaga() {
  //Collection
  yield takeEvery(GET_COLLECTIONS, fetchCollections)
  yield takeEvery(DELETE_COLLECTION, onDeleteCollection)
  yield takeEvery(ADD_NEW_COLLECTION, onAddNewCollection)
  yield takeEvery(GET_COLLECTION_DETAIL, fetchCollectionDetail)
  yield takeEvery(UPDATE_COLLECTION, onUpdateCollection)
  yield takeEvery(GET_COLLECTION_PARAMETERS, fetchCollectionParameters)
  yield takeEvery(UPDATE_TESTREPORT_INVOICE, onUpdateTestReportInvoice)

  yield takeEvery(GET_SAMPLE_TYPES, fetchSampleTypes)
  yield takeEvery(GET_SAMPLE_STATUSES, fetchSampleStatuses)
  yield takeEvery(GET_PARAMETERS, fetchParamters)
  yield takeEvery(GET_PARAMETER_TYPES, fetchParamterTypes)
  yield takeEvery(GET_TEST_REPORT, fetchTestReport)
  yield takeEvery(UPDATE_TEST_REPORT, onUpdateTestReportDetail)

  //Booking
  yield takeEvery(GET_BOOKINGS, fetchBookings)
  yield takeEvery(DELETE_BOOKING, onDeleteBooking)
  yield takeEvery(ADD_NEW_BOOKING, onAddNewBooking)
  yield takeEvery(GET_BOOKING_DETAIL, fetchBookingDetail)
  yield takeEvery(UPDATE_BOOKING, onUpdateBooking)

  //Section
  yield takeEvery(GET_SECTION_TYPES, fetchSectionTypes)
  yield takeEvery(GET_SECTIONS, fetchSections)
  yield takeEvery(GET_SECTION_DETAIL, fetchSectionDetail)
  yield takeEvery(DELETE_SECTION, onDeleteSection)
  yield takeEvery(ADD_NEW_SECTION, onAddNewSection)
  yield takeEvery(UPDATE_SECTION, onUpdateSection)
  yield takeEvery(GET_SECTION_PARAMETERS, fetchSectionParameters)

  //Dispatch
  yield takeEvery(GET_DISPATCHES, fetchDispatches)
  yield takeEvery(GET_DISPATCH_DETAIL, fetchDispatchDetail)
  yield takeEvery(DELETE_DISPATCH, onDeleteDispatch)
  yield takeEvery(ADD_NEW_DISPATCH, onAddNewDispatch)
  yield takeEvery(UPDATE_DISPATCH, onUpdateDispatch)

  //Company
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetail)
  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)

  //Full Sample
  yield takeEvery(GET_SAMPLE_DETAIL, fetchSampleDetail)

  yield takeEvery(GET_SOURCES, fetchSources)
  yield takeEvery(GET_FUELS, fetchFuels)
  yield takeEvery(GET_CONSUMPTIONS, fetchConsumptios)
  yield takeEvery(GET_PROTOCOLS, fetchProtocols)
  yield takeEvery(GET_UNITS, fetchUnits)
  yield takeEvery(GET_STANDARDLIMITS, fetchStandardLimits)
  yield takeEvery(GET_APCS, fetchAPCS)
  yield takeEvery(GET_DG_TYPES, fetchDGTyes)
  yield takeEvery(GET_DG_STANDARDLIMITS, fetchDGStandardLimits)
  yield takeEvery(GET_DG_VISIBILITY, fetchDGVisibility)
}

export default sampleSaga

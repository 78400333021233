import CryptoJS from "crypto-js"

const getAuthUser = () => {
  return decryptData("authUser")
}

const getRefreshToken = () => {
  return decryptData("authUser").refresh_token
}

const getTokenExpiry = () => {
  return decryptData("authUser").expires_in
}

const getAccessToken = () => {
  return decryptData("authUser").access_token
}

const getSecretKey = () => {
  return "cj1G~QwRy7N6~Bq"
}

const isAuthTokenValid = () => {
  if (!getTokenExpiry()) {
    return false
  }

  const now = Date.now()
  if (now < getTokenExpiry()) {
    return false
  }
  return true
}

const encryptData = data => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), getSecretKey()).toString()
}

const decryptData = name => {
  const encrypted = localStorage.getItem(name)
  if (encrypted) {
    return JSON.parse(
      CryptoJS.AES.decrypt(encrypted, getSecretKey()).toString(
        CryptoJS.enc.Utf8
      )
    )
  }
  return {
    expires_in: null,
    refresh_token: null,
    access_token: null,
  }
}

export {
  encryptData,
  getAccessToken,
  getRefreshToken,
  isAuthTokenValid,
  getAuthUser,
}

import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewCollection as onAddNewCollection,
  updateCollection as onUpdateCollection,
  getCollectionDetail as onGetCollectionDetail,
  getSampleTypes as onGetSampleTypes,
  getSampleStatuses as onGetSampleStatuses,
  getParameterTypes as onGetParameterTypes,
  getCompanies as onGetCompanies,
  getCollectionParameters as onGetCollectionParameters,
  getSources as onGetSources,
  getFuels as onGetFuels,
  getConsumptions as onGetConsumptions,
  getProtocols as onGetProtocols,
  getUnits as onGetUnits,
  getStandardLimits as onGetStandardLimits,
  getAPCS as onGetAPCS,
  getDGtypes as onGetDGtypes,
  getDGStandardLimits as onGetDGStandardLimits,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import { RESET_COLLECTION } from "store/sample/actionTypes"

function SampleCollection() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [sourceTypeID, setSourceTypeID] = useState(0)
  const [sampleTypeID, setSampleTypeID] = useState(0)
  const { id } = routeParams
  let counter = 0
  let index = 0

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Companies: yup.object().required("Required"),
    SampleTypes: yup.object().required("Required"),
    CollectionParameters: yup.array().of(
      yup.object().shape({
        ParameterID: yup.number(),
        Parameter: yup.string(),
        ParameterTypeID: yup.number(),
        Protocols: yup.object().nullable(),
        Units: yup.object().nullable(),
        StandardLimits: yup.object().nullable(),
        DGOne: yup.object().nullable(),
        DGTwo: yup.object().nullable(),
        DGThree: yup.object().nullable(),
        DGFour: yup.object().nullable(),
        DGFive: yup.object().nullable(),
        DGSix: yup.object().nullable(),
        DGSeven: yup.object().nullable(),
        Linked: yup.bool().default(false),
      })
    ),
    SampleDate: yup.string().required("Required"),
    SampleQuantity: yup.number().nullable(),
    IssuedTo: yup.string().required("Required"),
    IssuedDate: yup.string().required("Required"),
    ReceivedBy: yup.string().required("Required"),
    SampleStatuses: yup.object().required("Required"),
    VerifiedBy: yup.string().required("Required"),
    Remark: yup.string(),
    SampleLocation: yup.string().required("Required"),
    SamplingProtocol: yup.string().required("Required"),
    SampleConditionSampling: yup.string().nullable(),
    Sources: yup
      .object()
      .when("SampleTypes", {
        is: value => value?.SampleTypeID === 8,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    Fuels: yup.object().nullable(),
    ConsumptionValue: yup.number(),
    Consumptions: yup.object().nullable(),
    SourceCapacity: yup.string().nullable(),
    AmbientTemperature: yup.string().nullable(),
    RelativeHumidity: yup.string().nullable(),
    SamplingHeight: yup.string().nullable(),
    TypeOfFuels: yup.string().nullable(),
    SamplingDuration: yup.string().nullable(),
    FlowRate: yup.string().nullable(),
    StackHeightGroundLevel: yup.string().nullable(),
    StackDiameter: yup.string().nullable(),
    FlewGasTemperature: yup.string().nullable(),
    QuantityOfEmission: yup.string().nullable(),
    WeatherCondition: yup.string().nullable(),
    IndoorTemperature: yup.string().nullable(),
    WindDirection: yup.string().nullable(),
    ConsumptionOfFuels: yup.string().nullable(),
    CurrentLoad: yup.string().nullable(),
    FlowRateOfGases: yup.string().nullable(),
    StackHeightRoofLevel: yup.string().nullable(),
    AverageVelocity: yup.string().nullable(),
    APCS: yup.object().nullable().nullable(),
    MonitoringDuration: yup.object().nullable().nullable(),
    StackTemperature: yup.object().nullable().nullable(),
    Active: yup.bool().nullable(),
  })

  const formdefault = {
    IssuedTo: "",
    IssuedDate: "",
    SampleQuantity: 1,
    ReceivedBy: "",
    VerifiedBy: "",
    SampleDate: "",
    Remark: "",
    ConsumptionValue: 0,
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger, watch } =
    methods
  const { errors, isValid } = formState

  let currentSampleType = watch("SampleTypes")
  let currentSource = watch("Sources")

  const { fields, append, remove } = useFieldArray({
    name: "CollectionParameters",
    control,
  })

  const {
    collection,
    sampletypes,
    samplestatuses,
    parametertypes,
    companies,
    collectionparameters,
    sources,
    fuels,
    consumptions,
    protocols,
    units,
    standardlimits,
    apcs,
    dgstandardlimits,
    loading,
  } = useSelector(state => state.sample)

  useDeepCompareEffect(() => {
    dispatch(onGetSampleTypes())
    dispatch(onGetSampleStatuses())
    dispatch(onGetParameterTypes())
    dispatch(onGetCompanies())
    dispatch(onGetCollectionDetail(id))

    if (id === "new") {
      reset({
        ...formdefault,
        CollectionParameters: [],
      })
    } else {
      reset({ ...collection, CollectionParameters: collectionparameters })
    }
  }, [dispatch, id, collection])

  const uniqueKeyValuePairs = collectionparameters.reduce((acc, obj) => {
    const { ParameterTypeID, ParameterType } = obj
    const key = `${ParameterTypeID}_${ParameterType}`
    if (!acc[key]) {
      acc[key] = { ParameterTypeID, ParameterType }
    }
    return acc
  }, {})

  useEffect(() => {
    if (!isEmpty(collectionparameters)) {
      // Trigger a re-render by updating the form state
      setValue("CollectionParameters", collectionparameters)
    }
  }, [collectionparameters])

  useEffect(() => {
    if (!isEmpty(currentSampleType)) {
      setSampleTypeID(currentSampleType.SampleTypeID)
      dispatch(
        onGetCollectionParameters(
          currentSampleType.SampleTypeID,
          collection.SampleCollectionID,
          0
        )
      )

      //Stack Emission / Source Emission
      if (currentSampleType.SampleTypeID === 8) {
        dispatch(onGetSources(currentSampleType.SampleTypeID))
      }
    }
  }, [currentSampleType])

  useEffect(() => {
    if (!isEmpty(currentSource)) {
      let source = sources.filter(x => x.SourceID === currentSource.SourceID)
      setSourceTypeID(source[0]?.SourceTypeID)
    }
  }, [currentSource, sources])

  useEffect(() => {
    if (sourceTypeID > 0) {
      dispatch(onGetFuels(sourceTypeID))
      dispatch(onGetConsumptions(sourceTypeID))
      dispatch(onGetProtocols(sourceTypeID))
      dispatch(onGetUnits(sourceTypeID))
      dispatch(onGetStandardLimits(sourceTypeID))
      dispatch(onGetAPCS(sourceTypeID))
      dispatch(onGetDGStandardLimits())
    }
  }, [sourceTypeID])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams
      if (id === "new") {
        dispatch(onAddNewCollection(getValues()))
      } else {
        dispatch(onUpdateCollection(getValues()))
      }
      navigate("/collections")
    }
  }

  const onCancelClick = () => {
    navigate("/collections")
  }

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_COLLECTION })
    }
  }, [])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Collections"
            breadcrumbItem="Sample Collection Details"
          />
          {routeParams.id !== "new" && isEmpty(collection) ? (
            <EmptyContainer
              backURL="/collections"
              message="There is no such collection!"
              linkText="Go to Colletions Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormProvider {...methods}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <p className="text-success">
                              <strong>Sample Plan ID - Auto Generated</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Customer/Company Name
                            </label>
                            <Controller
                              name="Companies"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="Companies"
                                    options={companies}
                                    getOptionLabel={option =>
                                      option.CompanyName
                                    }
                                    getOptionValue={option =>
                                      option.SampleCompanyID
                                    }
                                    required
                                    aria-invalid={!!errors.Companies}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.Companies?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.Companies?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">Issued To</label>
                                <Controller
                                  name="IssuedTo"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="IssuedTo"
                                        type="text"
                                        required
                                        invalid={!!errors.IssuedTo}
                                      />
                                      {errors?.IssuedTo?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.IssuedTo?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Issued Date
                                </label>
                                <Controller
                                  name="IssuedDate"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Flatpickr
                                        {...field}
                                        className="form-control d-block"
                                        id="IssuedDate"
                                        options={{
                                          dateFormat: "d M, Y",
                                        }}
                                        onChange={(
                                          selectedDates,
                                          dateStr,
                                          instance
                                        ) => {
                                          setValue("IssuedDate", dateStr)
                                        }}
                                        required
                                        aria-invalid={!!errors.IssuedDate}
                                      />
                                      {errors?.IssuedDate?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.IssuedDate?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Received By
                                </label>
                                <Controller
                                  name="ReceivedBy"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="ReceivedBy"
                                        type="text"
                                        required
                                        invalid={!!errors.ReceivedBy}
                                      />
                                      {errors?.ReceivedBy?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.ReceivedBy?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Verified By
                                </label>
                                <Controller
                                  name="VerifiedBy"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="VerifiedBy"
                                        type="text"
                                        required
                                        invalid={!!errors.VerifiedBy}
                                      />
                                      {errors?.VerifiedBy?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.VerifiedBy?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Environmental Temperature at the time of
                                  Sampling
                                </label>
                                <Controller
                                  name="SampleConditionSampling"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SampleConditionSampling"
                                        type="text"
                                        required
                                        invalid={
                                          !!errors.SampleConditionSampling
                                        }
                                      />
                                      {errors?.SampleConditionSampling
                                        ?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {
                                            errors?.SampleConditionSampling
                                              ?.message
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label mb-4">
                                  Sample Location
                                </label>
                                <Controller
                                  name="SampleLocation"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SampleLocation"
                                        type="text"
                                        required
                                        invalid={!!errors.SampleLocation}
                                      />
                                      {errors?.SampleLocation?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SampleLocation?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          {(sampleTypeID === 7 || sampleTypeID === 16) && (
                            <>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Ambient Temperature° C
                                    </label>
                                    <Controller
                                      name="AmbientTemperature"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="AmbientTemperature"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Weather Condition
                                    </label>
                                    <Controller
                                      name="WeatherCondition"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="WeatherCondition"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Relative Humidity %
                                    </label>
                                    <Controller
                                      name="RelativeHumidity"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="RelativeHumidity"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Indoor Temperature° C
                                    </label>
                                    <Controller
                                      name="IndoorTemperature"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="IndoorTemperature"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Wind Direction
                                    </label>
                                    <Controller
                                      name="WindDirection"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="WindDirection"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {sampleTypeID === 8 && (
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Source Capacity
                                  </label>
                                  <Controller
                                    name="SourceCapacity"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Input
                                          {...field}
                                          id="SourceCapacity"
                                          type="text"
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Consumption of Fuels
                                  </label>
                                  <Controller
                                    name="ConsumptionOfFuels"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Input
                                          {...field}
                                          id="ConsumptionOfFuels"
                                          type="text"
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            className="row"
                            hidden={currentSampleType?.SampleTypeID !== 8}
                          >
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Stack/Source
                                </label>
                                <div>
                                  <div hidden={id === "new"}>
                                    {getValues("Source")}
                                  </div>
                                  <div hidden={id !== "new"}>
                                    <Controller
                                      name="Sources"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Select
                                            {...field}
                                            id="Sources"
                                            options={sources}
                                            getOptionLabel={option =>
                                              option.Source
                                            }
                                            getOptionValue={option =>
                                              option.SourceID
                                            }
                                            aria-invalid={!!errors.Sources}
                                            classNamePrefix="select2-selection"
                                          />
                                          {errors?.Sources?.message ? (
                                            <FormFeedback
                                              type="invalid"
                                              className="d-block"
                                            >
                                              {errors?.Sources?.message}
                                            </FormFeedback>
                                          ) : null}
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">Fuel</label>
                                <div>
                                  <Controller
                                    name="Fuels"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Select
                                          {...field}
                                          id="Fuels"
                                          options={fuels}
                                          getOptionLabel={option => option.Fuel}
                                          getOptionValue={option =>
                                            option.FuelID
                                          }
                                          classNamePrefix="select2-selection"
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {currentSampleType?.SampleTypeID === 8 &&
                          sourceTypeID === 1 ? (
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Consumption
                                  </label>
                                  <Controller
                                    name="ConsumptionValue"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Input
                                          {...field}
                                          id="ConsumptionValue"
                                          type="text"
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">&nbsp;</label>
                                  <div>
                                    <Controller
                                      name="Consumptions"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Select
                                            {...field}
                                            id="Consumptions"
                                            options={consumptions}
                                            getOptionLabel={option =>
                                              option.Consumption
                                            }
                                            getOptionValue={option =>
                                              option.ConsumptionID
                                            }
                                            classNamePrefix="select2-selection"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-4">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sample Type
                                </label>
                                <div hidden={id === "new"}>
                                  {getValues("SampleType")}
                                </div>
                                <div hidden={id !== "new"}>
                                  <Controller
                                    name="SampleTypes"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Select
                                          {...field}
                                          id="SampleTypes"
                                          options={sampletypes}
                                          getOptionLabel={option =>
                                            option.SampleType
                                          }
                                          getOptionValue={option =>
                                            option.SampleTypeID
                                          }
                                          required
                                          invalid={!!errors.SampleTypes}
                                          classNamePrefix="select2-selection"
                                        />
                                        {errors?.SampleTypes?.message ? (
                                          <FormFeedback
                                            type="invalid"
                                            className="d-block"
                                          >
                                            {errors?.SampleTypes?.message}
                                          </FormFeedback>
                                        ) : null}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sample Date
                                </label>
                                <Controller
                                  name="SampleDate"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Flatpickr
                                        {...field}
                                        className="form-control d-block"
                                        id="SampleDate"
                                        options={{
                                          dateFormat: "d M, Y",
                                        }}
                                        onChange={(
                                          selectedDates,
                                          dateStr,
                                          instance
                                        ) => {
                                          setValue("SampleDate", dateStr)
                                        }}
                                        required
                                        aria-invalid={!!errors.SampleDate}
                                      />
                                      {errors?.SampleDate?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SampleDate?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">                           
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <Label>Active</Label>
                                <div>
                                  <Controller
                                    name="Active"
                                    control={control}
                                    render={({ field }) => (
                                      <>
                                        <Switch
                                          {...field}
                                          id="Active"
                                          checked={field.value}
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          onColor="#626ed4"
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            {sampleTypeID === 8 && (
                              <>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Stack Temperature
                                    </label>
                                    <Controller
                                      name="StackTemperature"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="StackTemperature"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sample Status
                                </label>
                                <Controller
                                  name="SampleStatuses"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Select
                                        {...field}
                                        id="SampleStatuses"
                                        options={samplestatuses}
                                        getOptionLabel={option =>
                                          option.SampleStatus
                                        }
                                        getOptionValue={option =>
                                          option.SampleStatusID
                                        }
                                        required
                                        aria-invalid={!!errors.SampleStatuses}
                                        classNamePrefix="select2-selection"
                                      />
                                      {errors?.SampleStatuses?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SampleStatuses?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sampling Protocol
                                </label>
                                <Controller
                                  name="SamplingProtocol"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SamplingProtocol"
                                        type="text"
                                        required
                                        invalid={!!errors.SamplingProtocol}
                                      />
                                      {errors?.SamplingProtocol?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SamplingProtocol?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            {sampleTypeID === 8 && (
                              <>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Average velocity [m/sec.]
                                    </label>
                                    <Controller
                                      name="AverageVelocity"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="AverageVelocity"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Sampling Duration
                                    </label>
                                    <Controller
                                      name="SamplingDuration"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="SamplingDuration"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Current Load
                                    </label>
                                    <Controller
                                      name="CurrentLoad"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="CurrentLoad"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Stack Diameter (at Port hole)
                                    </label>
                                    <Controller
                                      name="StackDiameter"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="StackDiameter"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          {sampleTypeID === 8 && (
                            <>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Attached APCS
                                    </label>
                                    <Controller
                                      name="APCS"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Select
                                            {...field}
                                            id="APCS"
                                            options={apcs}
                                            getOptionLabel={option =>
                                              option.SourceAPCS
                                            }
                                            getOptionValue={option =>
                                              option.SourceAPCSID
                                            }
                                            classNamePrefix="select2-selection"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Stack height (from Roof level)
                                    </label>
                                    <Controller
                                      name="StackHeightRoofLevel"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="StackHeightRoofLevel"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row"></div>
                            </>
                          )}
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <label className="form-label">Remark</label>
                            <Controller
                              name="Remark"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <textarea
                                    {...field}
                                    id="Remark"
                                    className="form-control"
                                    rows={5}
                                  />
                                </>
                              )}
                            />
                          </div>
                          {sampleTypeID === 8 && (
                            <>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Sampling Height
                                    </label>
                                    <Controller
                                      name="SamplingHeight"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="SamplingHeight"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Type of Fuels
                                    </label>
                                    <Controller
                                      name="TypeOfFuels"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="TypeOfFuels"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Flow Rate of SPM [LPM]
                                    </label>
                                    <Controller
                                      name="FlowRate"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="FlowRate"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Stack Height (from Ground level)
                                    </label>
                                    <Controller
                                      name="StackHeightGroundLevel"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="StackHeightGroundLevel"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Flow Rate of Gases [LPM]
                                    </label>
                                    <Controller
                                      name="FlowRateOfGases"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="FlowRateOfGases"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Duration of Monitoring
                                    </label>
                                    <Controller
                                      name="MonitoringDuration"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="MonitoringDuration"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Flew gas temperature. (°C)
                                    </label>
                                    <Controller
                                      name="FlewGasTemperature"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="FlewGasTemperature"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Quantity of Emission (m3/Hrs.)
                                    </label>
                                    <Controller
                                      name="QuantityOfEmission"
                                      control={control}
                                      render={({ field }) => (
                                        <>
                                          <Input
                                            {...field}
                                            id="QuantityOfEmission"
                                            type="text"
                                          />
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <table
                        style={{ marginTop: 15 }}
                        className="table table-bordered"
                      >
                        <thead>
                          <tr className="bg-secondary-subtle fw-bold">
                            <td
                              className="p-0 text-center border-start-0"
                              style={{
                                width: "10px",
                              }}
                            >
                              S.No
                            </td>
                            <td
                              className="p-0 text-center"
                              style={{
                                width: "220px",
                              }}
                            >
                              Parameters
                            </td>
                            <td
                              className="p-0 text-center"
                              style={{
                                width: "220px",
                              }}
                            >
                              Protocol
                            </td>
                            <td
                              className="p-0 text-center"
                              style={{
                                width: "220px",
                              }}
                            >
                              Unit
                            </td>
                            {sourceTypeID === 1 && (
                              <td
                                className="p-0 text-center"
                                style={{
                                  width: "220px",
                                }}
                              >
                                Standard Limit
                              </td>
                            )}
                            {sourceTypeID === 2 && (
                              <>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >
                                  {`NEW Standred limit DG SET < 19KW`}
                                </td>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >
                                  {" "}
                                  {`NEW Standred limit DG SET < 75KW`}
                                </td>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >{`NEW Standred limit DG SET < 800KW `}</td>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >{`Standred limit DG SET < 19KW(23.75KVA)`}</td>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >{`Standred limit DG SET < 75KW(93.75KVA)`}</td>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >
                                  {" "}
                                  {`Standred limit DG SET < 800KW(1000KVA) `}
                                </td>
                                <td
                                  className="p-0 text-center"
                                  style={{
                                    width: "220px",
                                  }}
                                >{`Standred limit DG SET > 1000KVA `}</td>
                              </>
                            )}
                            <td
                              className="p-0 text-center border-end-0"
                              style={{
                                width: "20px",
                              }}
                            ></td>
                          </tr>
                        </thead>
                        {!isEmpty(collectionparameters) &&
                          Object.values(uniqueKeyValuePairs).map((pr, i) => (
                            <>
                              <tr key={"parent" + i}>
                                <td
                                  colSpan={sourceTypeID === 1 ? 6 : 12}
                                  style={{
                                    padding: 5,
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }}
                                >
                                  {pr.ParameterType}
                                </td>
                              </tr>
                              {fields &&
                                fields
                                  .filter(
                                    x =>
                                      x.ParameterTypeID === pr.ParameterTypeID
                                  )
                                  .map((ch, j) => {
                                    return (
                                      <>
                                        <tr key={"child" + ch.ParameterID}>
                                          <td>{(counter = counter + 1)}</td>
                                          <td
                                            dangerouslySetInnerHTML={{
                                              __html: ch.Parameter,
                                            }}
                                          ></td>
                                          <td>
                                            {sampleTypeID === 8 ? (
                                              <Controller
                                                name={`CollectionParameters[${index}].Protocols`}
                                                control={control}
                                                render={({ field }) => (
                                                  <>
                                                    <Select
                                                      {...field}
                                                      id={`CollectionParameters[${index}].Protocols`}
                                                      options={protocols}
                                                      getOptionLabel={option =>
                                                        option.SourceProtocol
                                                      }
                                                      getOptionValue={option =>
                                                        option.SourceProtocolID
                                                      }
                                                      classNamePrefix="select2-selection"
                                                    />
                                                  </>
                                                )}
                                              />
                                            ) : (
                                              ch.TestMethod
                                            )}
                                          </td>
                                          {sampleTypeID === 8 ? (
                                            <td>
                                              <Controller
                                                name={`CollectionParameters[${index}].Units`}
                                                control={control}
                                                render={({ field }) => (
                                                  <>
                                                    <Select
                                                      {...field}
                                                      id={`CollectionParameters[${index}].Units`}
                                                      options={units}
                                                      getOptionLabel={option =>
                                                        option.SourceUnit
                                                      }
                                                      getOptionValue={option =>
                                                        option.SourceUnitID
                                                      }
                                                      classNamePrefix="select2-selection"
                                                    />
                                                  </>
                                                )}
                                              />
                                            </td>
                                          ) : (
                                            <td
                                              dangerouslySetInnerHTML={{
                                                __html: ch.Unit,
                                              }}
                                            ></td>
                                          )}
                                          {sourceTypeID === 1 && (
                                            <td>
                                              <Controller
                                                name={`CollectionParameters[${index}].StandardLimits`}
                                                control={control}
                                                render={({ field }) => (
                                                  <>
                                                    <Select
                                                      {...field}
                                                      id={`CollectionParameters[${index}].StandardLimits`}
                                                      options={standardlimits}
                                                      getOptionLabel={option =>
                                                        option.SourceStandardLimit
                                                      }
                                                      getOptionValue={option =>
                                                        option.SourceStandardLimitID
                                                      }
                                                      classNamePrefix="select2-selection"
                                                    />
                                                  </>
                                                )}
                                              />
                                            </td>
                                          )}
                                          {sourceTypeID === 2 && (
                                            <>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGOne`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGOne`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            1
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGTwo`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGTwo`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            2
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGThree`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGThree`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            3
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGFour`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGFour`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            4
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGFive`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGFive`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            5
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGSix`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGSix`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            6
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                              <td>
                                                <Controller
                                                  name={`CollectionParameters[${index}].DGSeven`}
                                                  control={control}
                                                  render={({ field }) => (
                                                    <>
                                                      <Select
                                                        {...field}
                                                        id={`CollectionParameters[${index}].DGSeven`}
                                                        options={dgstandardlimits.filter(
                                                          x =>
                                                            x.DGStandardLimitTypeID ===
                                                            7
                                                        )}
                                                        getOptionLabel={option =>
                                                          option.DGStandardLimit
                                                        }
                                                        getOptionValue={option =>
                                                          option.DGStandardLimitID
                                                        }
                                                        classNamePrefix="select2-selection"
                                                      />
                                                    </>
                                                  )}
                                                />
                                              </td>
                                            </>
                                          )}
                                          <td>
                                            <div style={{ padding: 0 }}>
                                              <Controller
                                                name={`CollectionParameters[${index}].Linked`}
                                                control={control}
                                                render={({ field }) => (
                                                  <>
                                                    <Switch
                                                      {...field}
                                                      id={`CollectionParameters[${index}].Linked`}
                                                      checked={field.value}
                                                      uncheckedIcon={
                                                        <Offsymbol />
                                                      }
                                                      checkedIcon={<OnSymbol />}
                                                      className="me-1 mb-sm-8 "
                                                      onColor="#626ed4"
                                                    />
                                                  </>
                                                )}
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                        <span hidden={true}>
                                          {(index = index + 1)}
                                        </span>
                                      </>
                                    )
                                  })}
                            </>
                          ))}
                      </table>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default SampleCollection

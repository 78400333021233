import React, { useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "components/Common/TableContainer"

//import components
import DeleteModal from "components/Common/DeleteModal"

// import {
//   getDocuments as onGetDocuments,
//   deleteDocument as onDeleteDocument,
// } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import DocumentModal from "./Modal/DocumentModal"

function Document(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    objectID: props.objectID ?? 0,
    objectTypeID: props.objectTypeID,
  })
  const { documents, loading } = useSelector(state => state.document)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [documentModal, setDocumentModal] = useState(false)
  const [document, setDocument] = useState(null)

  useEffect(() => {
    //dispatch(onGetDocuments(filter))
  }, [dispatch])

  const handleAddClick = () => {
    setDocumentModal(true)
  }

  const onClickDelete = arg => {
    setDocument(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (document && document.ObjectArtifactID) {
      //dispatch(onDeleteDocument(document.ObjectArtifactID))
      setDeleteModal(false)
    }
  }

  const onCloseModal = () => {
    setDocumentModal(false)
  }

  const DocumentStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "File Name",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.FileName}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                <DocumentStatus status={cellProps.row.original.IsActive} />
              </p>
            </>
          )
        },
      },
      {
        Header: "Format",
        accessor: "Type",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Expiration Date",
        accessor: "ExpiryDate",
        Cell: cellProps => {
          return cellProps.value
            ? Moment(cellProps.value).format("DD MMM yyyy")
            : "Never"
        },
      },
      {
        Header: "Uploaded",
        accessor: "CreatedDate",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {Moment(cellProps.row.original.CreatedDate).format(
                    "DD MMM yyyy"
                  )}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                {cellProps.row.original.UploadedBy}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {documentModal && (
        <DocumentModal
          show={documentModal}
          onCloseClick={() => onCloseModal()}
          objectTypeID={props.objectTypeID}
          objectID={props.objectID}
        />
      )}

      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={documents}
                  isAddOption={true}
                  addOptionText="Upload document"
                  handleAddClick={handleAddClick}
                  customPageSize={10}
                  isPagination={true}
                  tableClass="align-middle table-nowrap table-check"
                  theadClass="table-light"
                  pagination="pagination pagination-rounded justify-content-end mb-2"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export default Document

import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import { getUserImagePath } from "helpers/imageService"
import profileImg from "../../assets/images/profile-img.png"

const WelcomeComp = ({ profile }) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-success-subtle">
          <Row>
            <Col xs="7">
              <div className="text-success p-3">
                <h5 className="text-success">Welcome Back !</h5>
                <p>Green Admire Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  alt={profile.username}
                  src={getUserImagePath(profile.RowID, "image.jpeg")}
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15">{profile.username}</h5>
              <p className="text-muted mb-0">{profile.Role}</p>
            </Col>
            {/* <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">$0</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$0</h5>
                    <p className="text-muted mb-0">Unpaid</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="" className="btn btn-success  btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col> */}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp

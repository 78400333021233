import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"
import Moment from "moment"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewBooking as onAddNewBooking,
  updateBooking as onUpdateBooking,
  getCollections as onGetCollections,
  getBookingDetail as onGetBookingDetail,
  getCollectionDetail as onGetCollectionDetail,
  getSectionTypes as onGetSectionTypes,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { RESET_COLLECTION } from "store/sample/actionTypes"

function SampleBooking() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [isEditable, setIsEditable] = useState(true)

  const { id } = routeParams

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    SampleCollections: yup.object().required("Required"),
    LTRIssueDate: yup.string().required("Required"),
    BookedBy: yup.string().required("Required"),
    SectionTypes: yup.array().required("Required"),
    Active: yup.bool(),
    SampleQuantity: yup.string().required("Required"),
    SampleSource: yup.string().required("Required"),
    WaterTemperature: yup.string().nullable(),
    SamplePreservation: yup.string().required("Required"),
    SampleConditionReceiving: yup.string().required("Required"),
  })

  const formDefault = {
    LTRIssueDate: "",
    BookedBy: "",
    SectionTypes: [],
    WaterTemperature: "",
    Active: false,
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: formDefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger, watch } =
    methods
  const { errors, isValid } = formState

  const { collections, collection, booking, sectiontypes, loading } =
    useSelector(state => state.sample)

  useDeepCompareEffect(() => {
    if (id !== "new") {
      setIsEditable(false)
      dispatch(onGetBookingDetail(id))
      dispatch(onGetCollectionDetail(booking.CollectionRowID))
    }

    if (collections && !collections.length) {
      dispatch(onGetCollections())
    }
  }, [dispatch, collections])

  const SetCollection = e => {
    setValue("SampleCollections", {
      SampleCollectionID: e.SampleCollectionID,
      SampleCode: e.SampleCode,
    })
    dispatch(onGetSectionTypes(e.SampleTypeID))
    dispatch(onGetCollectionDetail(e.RowID))
  }

  useEffect(() => {
    if (!isEmpty(collection)) {
      dispatch(onGetSectionTypes(collection.SampleTypeID))
    }
  }, [dispatch, collection])

  useEffect(() => {
    if (booking) {
      reset(booking)
    }
  }, [booking])

  useEffect(() => {
    if (id === "new") {
      reset(formDefault)
    }
  }, [id])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewBooking(getValues()))
      } else {
        dispatch(onUpdateBooking(getValues()))
      }
      dispatch({ type: RESET_COLLECTION })
      navigate("/bookings")
    }
  }

  const onCancelClick = () => {
    dispatch({ type: RESET_COLLECTION })
    navigate("/bookings")
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  const sortedCollections = useMemo(() => {
    const sorted = collections
      .slice()
      .sort((a, b) => b.SampleCollectionID - a.SampleCollectionID)
    return sorted
  }, [collections])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Booking Details" />
          {routeParams.id !== "new" && isEmpty(booking) ? (
            <EmptyContainer
              backURL="/bookings"
              message="There is no such booking!"
              linkText="Go to Bookings Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <FormProvider {...methods}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <p className="text-success">
                              <strong>LTR NO. - Auto Generated</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="mb-3">
                            <Label>Sample Plan ID</Label>
                            <Controller
                              name="SampleCollections"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="SampleCollections"
                                    options={sortedCollections}
                                    getOptionLabel={option => option.SampleCode}
                                    getOptionValue={option =>
                                      option.SampleCollectionID
                                    }
                                    required
                                    isDisabled={!isEditable}
                                    aria-invalid={!!errors.SampleCollections}
                                    classNamePrefix="select2-selection"
                                    onChange={x => SetCollection(x)}
                                  />
                                  {errors?.SampleCollections?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.SampleCollections?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Section Type</label>
                            <Controller
                              name="SectionTypes"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="SectionTypes"
                                    options={sectiontypes}
                                    getOptionLabel={option =>
                                      option.SectionType
                                    }
                                    getOptionValue={option =>
                                      option.SectionTypeID
                                    }
                                    isMulti
                                    onChange={(e, { value }) => {
                                      const values = e.map(v =>
                                        sectiontypes.find(
                                          item =>
                                            item.SectionTypeID ==
                                            v.SectionTypeID
                                        )
                                      )
                                      setValue("SectionTypes", values)
                                    }}
                                    required
                                    aria-invalid={!!errors.SectionTypes}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.SectionTypes?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.SectionTypes?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <p>
                              Sample Type -{" "}
                              <strong>{collection.SampleType ?? "-"}</strong>{" "}
                            </p>
                            {/* <p>
                              Parameter Name -{" "}
                              <strong>
                                {" "}
                                {collection.TestParameters
                                  ? JSON.parse(collection.TestParameters)
                                      .map(item => item.ParameterType)
                                      .join(", ")
                                  : "-"}
                              </strong>
                            </p> */}
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  LTR Issue Date
                                </label>
                                <Controller
                                  name="LTRIssueDate"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Flatpickr
                                        {...field}
                                        className="form-control d-block"
                                        id="LTRIssueDate"
                                        options={{
                                          dateFormat: "d M, Y",
                                        }}
                                        onChange={(
                                          selectedDates,
                                          dateStr,
                                          instance
                                        ) => {
                                          setValue("LTRIssueDate", dateStr)
                                        }}
                                        required
                                        invalid={!!errors.LTRIssueDate}
                                      />
                                      {errors?.LTRIssueDate?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.LTRIssueDate?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">Booked By</label>
                                <Controller
                                  name="BookedBy"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="BookedBy"
                                        type="text"
                                        required
                                        invalid={!!errors.BookedBy}
                                      />
                                      {errors?.BookedBy?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.BookedBy?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sample Source
                                </label>
                                <Controller
                                  name="SampleSource"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SampleSource"
                                        type="text"
                                        required
                                        invalid={!!errors.SampleSource}
                                      />
                                      {errors?.SampleSource?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SampleSource?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sample Quantity
                                </label>
                                <Controller
                                  name="SampleQuantity"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SampleQuantity"
                                        type="text"
                                        required
                                        invalid={!!errors.SampleQuantity}
                                      />
                                      {errors?.SampleQuantity?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SampleQuantity?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {/* <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Water Temperature
                                </label>
                                <Controller
                                  name="WaterTemperature"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="WaterTemperature"
                                        type="text"
                                        required
                                        invalid={!!errors.WaterTemperature}
                                      />
                                      {errors?.WaterTemperature?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.WaterTemperature?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div> */}
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Preservation of Sample if Required (yes/no)
                                </label>
                                <Controller
                                  name="SamplePreservation"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SamplePreservation"
                                        type="text"
                                        required
                                        invalid={!!errors.SamplePreservation}
                                      />
                                      {errors?.SamplePreservation?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {errors?.SamplePreservation?.message}
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Sample Condition at the time of receiving
                                </label>
                                <Controller
                                  name="SampleConditionReceiving"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Input
                                        {...field}
                                        id="SampleConditionReceiving"
                                        type="text"
                                        required
                                        invalid={
                                          !!errors.SampleConditionReceiving
                                        }
                                      />
                                      {errors?.SampleConditionReceiving
                                        ?.message ? (
                                        <FormFeedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {
                                            errors?.SampleConditionReceiving
                                              ?.message
                                          }
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="row">
                            <div className="mb-3">
                              <p>
                                Sample Date -{" "}
                                <strong>{collection.SampleDate ?? "-"}</strong>
                              </p>
                              <p>
                                Sample Quantity -{" "}
                                <strong>
                                  {collection.SampleQuantity ?? "-"}
                                </strong>
                              </p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="mb-3">
                              <Label>Active</Label>
                              <div>
                                <Controller
                                  name="Active"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Switch
                                        {...field}
                                        id="Active"
                                        checked={field.value}
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#626ed4"
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

export default SampleBooking

import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Staff
import staff from "./staff/reducer"

//Role
import role from "./role/reducer"

//navigation
import navigation from "./navigation/reducer"

//sample
import sample from "./sample/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  role,
  staff,
  navigation,
  sample,
})

export default rootReducer

import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewStaff as onAddNewStaff,
  getRoles as onGetRoles,
  updateStaff as onUpdateStaff,
  updateStaffPhoto as onUpdateStaffPhoto,
  resetStaff as onResetStaff,
  getSectionTypes as onGetSectionTypes,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import LocationField from "components/Common/Location/Location"
import GenderField from "components/Common/Gender/Gender"
import PhotoField from "components/Common/Crop/PhotoField"
import Spinners from "components/Common/Spinner"
import { getUserImagePath } from "../../../helpers/imageService"

function BasicInfoTab({ staff, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    LoginUserName: yup.string().required("Required"),
    LoginPassword: yup.string().required("Required"),
    IsActive: yup.bool(),
    Email: yup.string().required("Required").email("Must be a valid Email"),
    PhoneNumber: yup
      .string()
      .min(0, "Should be between 0 and 10")
      .max(10, "Should be between 0 and 10"),
    Roles: yup.object().required("Required"),
    Genders: yup.object().required("Required"),
    SectionTypes: yup
      .object()
      .when("Roles", {
        is: value => value?.RoleID === 5,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      LoginUserName: "",
      LoginPassword: "",
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      Address: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const role = watch("Roles")

  useDeepCompareEffect(() => {
    function updateStaffState() {
      dispatch(onGetSectionTypes(0))
      dispatch(onGetRoles())

      //reset form with server data
      reset(staff)
    }

    updateStaffState()
  }, [dispatch])

  //////////////////ROLE STATE/////////////////////
  const { roles } = useSelector(state => state.role)
  const { sectiontypes } = useSelector(state => state.sample)
  ///////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetStaff())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/users")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewStaff(getValues()))
      } else {
        dispatch(onUpdateStaff(getValues()))
      }
      navigate("/users")
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="4">
          <div className="mb-3">
            <Label>First Name</Label>
            <Controller
              name="FirstName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="FirstName"
                    type="text"
                    required
                    invalid={!!errors.FirstName}
                  />
                  {errors?.FirstName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.FirstName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Last Name</Label>
            <Controller
              name="LastName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LastName"
                    type="text"
                    required
                    invalid={!!errors.LastName}
                  />
                  {errors?.LastName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LastName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Username</Label>
            <Controller
              name="LoginUserName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LoginUserName"
                    type="text"
                    required
                    disabled={routeParams.id !== "new"}
                    invalid={!!errors.LoginUserName}
                  />
                  {errors?.LoginUserName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginUserName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <GenderField />
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label>Email</Label>
            <Controller
              name="Email"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Email"
                    type="email"
                    required
                    invalid={!!errors.Email}
                  />
                  {errors?.Email?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Email?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Phone Number</Label>
            <Controller
              name="PhoneNumber"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="PhoneNumber"
                    type="number"
                    invalid={!!errors.PhoneNumber}
                  />
                  {errors?.PhoneNumber?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.PhoneNumber?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Password</Label>
            <Controller
              name="LoginPassword"
              control={control}
              render={({ field }) => (
                <>
                  <Input {...field} id="LoginPassword" type="password" />
                  {errors?.LoginPassword?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginPassword?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Role</Label>
            <Controller
              name="Roles"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Roles"
                    options={roles.filter(x => x.RoleID !== 1)}
                    getOptionLabel={option => option.RoleName}
                    getOptionValue={option => option.RoleID}
                    required
                    aria-invalid={!!errors.Roles}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Roles?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Roles?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          {role?.RoleID === 5 ? (
            <div className="mb-3">
              <label className="form-label">Section Type</label>
              <Controller
                name="SectionTypes"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="SectionTypes"
                      options={sectiontypes}
                      getOptionLabel={option => option.SectionType}
                      getOptionValue={option => option.SectionTypeID}
                      aria-invalid={!!errors.SectionTypes}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.SectionTypes?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.SectionTypes?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <div className="mb-3">
          <Label>Active</Label>
          <div>
            <Controller
              name="IsActive"
              control={control}
              render={({ field }) => (
                <>
                  <Switch
                    {...field}
                    id="IsActive"
                    checked={field.value}
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                  />
                </>
              )}
            />
          </div>
        </div>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="success" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab

import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Staff Redux States
import {
  GET_STAFFS,
  DELETE_STAFF,
  ADD_NEW_STAFF,
  GET_STAFF_DETAIL,
  UPDATE_STAFF,
  UPDATE_STAFF_PHOTO,
  RESET_STAFF,
  GET_PAYRATES,
  DELETE_PAYRATE,
  ADD_NEW_PAYRATE,
  GET_PAYRATE_DETAIL,
  UPDATE_PAYRATE,
  RESET_PAYRATE,
  GET_EMPLOYMENT_TYPES,
  GET_CLASSIFICATION_TYPES,
  GET_STAFF_AVAILABILITY,
  ADD_STAFF_AVAILABILITY,
  GET_STAFF_PROFILE,
  ADD_STAFF_PROFILE,
  ADD_STAFF_HAPPENING,
  GET_HAPPENING_TYPES,
  GET_STAFF_HAPPENING,
  DELETE_STAFF_HAPPENING,
  UPDATE_STAFF_HAPPENING,
  GET_STAFF_HAPPENING_DETAIL,
  GET_HOUR_TYPES,
  GET_GENDERS,
} from "./actionTypes"
import {
  getStaffsFail,
  getStaffsSuccess,
  deleteStaffSuccess,
  deleteStaffFail,
  addStaffSuccess,
  addStaffFail,
  getStaffDetailSuccess,
  getStaffDetailFail,
  updateStaffSuccess,
  updateStaffFail,
  updateStaffPhotoSuccess,
  updateStaffPhotoFail,
  resetStaffSuccess,
  getPayratesFail,
  getPayratesSuccess,
  deletePayrateSuccess,
  deletePayrateFail,
  addPayrateSuccess,
  addPayrateFail,
  getPayrateDetailSuccess,
  getPayrateDetailFail,
  updatePayrateSuccess,
  updatePayrateFail,
  resetPayrateSuccess,
  getEmploymentTypesSuccess,
  getEmploymentTypesFail,
  getClassificationTypesSuccess,
  getClassificationTypesFail,
  getStaffAvailabilitySuccess,
  getStaffAvailabilityFail,
  addStaffAvailabilitySuccess,
  addStaffAvailabilityFail,
  getStaffProfileSuccess,
  getStaffProfileFail,
  getHappeningTypesSuccess,
  getHappeningTypesFail,
  getStaffHappeningsSuccess,
  getStaffHappeningsFail,
  addStaffProfileSuccess,
  addStaffProfileFail,
  addStaffHappeningSuccess,
  addStaffHappeningFail,
  getStaffHappeningDetailSuccess,
  getStaffHappeningDetailFail,
  updateStaffHappeningSuccess,
  updateStaffHappeningFail,
  deleteStaffHappeningSuccess,
  deleteStaffHappeningFail,
  getHourTypesSuccess,
  getHourTypesFail,
  getGendersSuccess,
  getGendersFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStaffs,
  deleteStaff,
  addNewStaff,
  getStaffDetail,
  updateStaff,
  updateStaffPhoto,
  getPayrates,
  deletePayrate,
  addNewPayrate,
  getPayrateDetail,
  updatePayrate,
  resetPayrate,
  getEmployeementTypes,
  getClassificationTypes,
  getStaffAvailability,
  addStaffAvailability,
  getStaffProfile,
  addStaffProfile,
  getHappeningTypes,
  getStaffHappenings,
  addStaffHappening,
  getStaffHappeningDetail,
  updateStaffHappening,
  deleteStaffHappening,
  getHourTypes,
  getGenders,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  getCountryByID,
  getStateByID,
  getCityByID,
} from "../../helpers/location_helper"
function* fetchStaffs() {
  try {
    const response = yield call(getStaffs)
    yield put(getStaffsSuccess(response))
  } catch (error) {
    yield put(getStaffsFail(error))
  }
}

function* onDeleteStaff({ payload: staff }) {
  try {
    const response = yield call(deleteStaff, staff)
    yield put(deleteStaffSuccess(response))
    toast.success("Staff deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteStaffFail(error))
    toast.error("Staff deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewStaff({ payload: staff }) {
  try {
    delete staff.Photo
    const staff1 = {
      ...staff,
      RoleID: staff.Roles.RoleID,
      GenderID: staff.Genders.GenderID,
      SectionTypeID: staff.SectionTypes
        ? staff.SectionTypes.SectionTypeID
        : null,
    }
    const response = yield call(addNewStaff, staff1)
    yield put(addStaffSuccess(response[0]))
    toast.success("User added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addStaffFail(error))
    toast.error("User addition failed", { autoClose: 2000 })
  }
}

function* fetchStaffDetail({ staffId }) {
  try {
    const response = yield call(getStaffDetail, staffId)
    let result = response[0]

    result = {
      ...result,
      Roles: {
        RoleID: result.RoleID,
        RoleName: result.RoleName,
      },
      SectionTypes: {
        SectionTypeID: result.SectionTypeID,
        SectionType: result.SectionType,
      },
      PhoneNumber: result.PhoneNumber ?? "",
      Genders: {
        GenderID: result.GenderID ?? "",
        Gender: result.Gender ?? "Select...",
      },
    }

    yield put(getStaffDetailSuccess(result))
  } catch (error) {
    yield put(getStaffDetailFail(error))
  }
}

function* onUpdateStaff({ payload: staff1 }) {
  try {
    delete staff1.Photo
    const staff = {
      ...staff1,
      RoleID: staff1.Roles.RoleID,
      GenderID: staff1.Genders.GenderID,
      SectionTypeID: staff1.SectionTypes
        ? staff1.SectionTypes.SectionTypeID
        : null,
    }
    const response = yield call(updateStaff, staff)
    let result = response[0]

    yield put(updateStaffSuccess(result))
    toast.success("User updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateStaffFail(error))
    toast.error("User updation failed", { autoClose: 2000 })
  }
}
function* onUpdateStaffPhoto({ payload: staff }) {
  try {
    var photoData = new FormData()
    photoData.append("files", staff.Photo)
    photoData.append("refId", staff.UserID)
    photoData.append("field", "User")
    const response = yield call(updateStaffPhoto, photoData)
    yield put(updateStaffPhotoSuccess(response))
    //toast.success("Photo Update Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateStaffPhotoFail(error))
    toast.error("Photo Update Failed", { autoClose: 2000 })
  }
}

function* onResetStaff() {
  try {
    yield put(resetStaffSuccess({}))
  } catch (error) {}
}

// Payates
function* fetchPayrates({ payload: userID }) {
  try {
    const response = yield call(getPayrates, userID)
    yield put(getPayratesSuccess(response))
  } catch (error) {
    yield put(getPayratesFail(error))
  }
}

function* onDeletePayrate({ payload: payrate }) {
  try {
    const response = yield call(deletePayrate, payrate)
    yield put(deletePayrateSuccess(response))
    toast.success("Payrate deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deletePayrateFail(error))
    toast.error("Payrate deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewPayrate({ payload: payrate }) {
  try {
    const payrate1 = {
      ...payrate,
      Amount: Number(payrate.Amount),
      EmploymentTypeID: payrate.EmploymentTypes.EmploymentTypeID,
      ClassificationTypeID: payrate.ClassificationTypes.ClassificationTypeID,
    }
    const response = yield call(addNewPayrate, payrate1)
    yield put(addPayrateSuccess(response))
    toast.success("Payrate added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addPayrateFail(error))
    toast.error("Payrate add Failed", { autoClose: 2000 })
  }
}

function* fetchPayrateDetail({ payrateID }) {
  try {
    const response = yield call(getPayrateDetail, payrateID)
    let result = response[0]
    result = {
      ...result,
      EmploymentTypes: {
        EmploymentTypeID: Number(result.EmploymentTypeID),
        EmploymentType: result.EmploymentType,
      },
      ClassificationTypes: {
        ClassificationTypeID: Number(result.ClassificationTypeID),
        ClassificationType: result.ClassificationType,
      },
      Amount: result.Amount ?? "",
      StartDate: result.StartDate
        ? Moment(result.StartDate).format("DD MMM, YYYY")
        : "",
    }

    yield put(getPayrateDetailSuccess(result))
  } catch (error) {
    yield put(getPayrateDetailFail(error))
  }
}

function* onUpdatePayrate({ payload: payrate }) {
  try {
    const payrate1 = {
      ...payrate,
      // Amount: Number(payrate.Amount),
      EmploymentTypeID: payrate.EmploymentTypes.EmploymentTypeID,
      ClassificationTypeID: payrate.ClassificationTypes.ClassificationTypeID,
    }
    const response = yield call(updatePayrate, payrate1)
    let result = response[0]
    yield put(updatePayrateSuccess(result))
    toast.success("Payrate updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updatePayrateFail(error))
    toast.error("Payrate updation failed", { autoClose: 2000 })
  }
}
function* onResetPayrate() {
  try {
    yield put(resetPayrateSuccess({}))
  } catch (error) {}
}

function* fetchEmployeementTypes() {
  try {
    const response = yield call(getEmployeementTypes)
    yield put(getEmploymentTypesSuccess(response))
  } catch (error) {
    yield put(getEmploymentTypesFail(error))
  }
}

function* fetchClassificationTypes() {
  try {
    const response = yield call(getClassificationTypes)
    yield put(getClassificationTypesSuccess(response))
  } catch (error) {
    yield put(getClassificationTypesFail(error))
  }
}

function* fetchStaffAvailability({ staffID }) {
  try {
    const response = yield call(getStaffAvailability, staffID)
    yield put(getStaffAvailabilitySuccess(response))
  } catch (error) {
    yield put(getStaffAvailabilityFail(error))
  }
}

function* onAddStaffAvailability({ payload: { staffID, staffavailability } }) {
  try {
    const request = {
      staffID: staffID,
      staffavailability: staffavailability.availability,
    }
    const response = yield call(addStaffAvailability, request)
    yield put(addStaffAvailabilitySuccess(response.Table))
    toast.success("Staff availability added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addStaffAvailabilityFail(error))
    toast.error("Staff availability addition failed", { autoClose: 2000 })
  }
}
function* fetchStaffProfile({ staffId }) {
  try {
    const response = yield call(getStaffProfile, staffId)
    let country = getCountryByID(response.CountryID)
    let state = getStateByID(response.StateID)
    let city = getCityByID(response.CityID)

    let result = {
      ...response,
      Classes: {
        ClassID: response.HomeClassID,
        Title: response.HomeClass,
      },
      Countries: {
        id: country ? Number(country.id) : 0,
        name: country ? country.name : "",
      },
      States: {
        id: state ? Number(state.id) : 0,
        name: state ? state.name : "",
      },
      Cities: {
        id: city ? Number(city.id) : 0,
        name: city ? city.name : "",
      },
      ZipCode: response.ZipCode ? response.ZipCode : "",
    }
    yield put(getStaffProfileSuccess(result))
  } catch (error) {
    yield put(getStaffProfileFail(error))
  }
}
function* onAddStaffProfile({ payload: { staffId, staffprofile } }) {
  try {
    const request = {
      staffID: staffId,
      staffprofile: staffprofile,
    }
    const response = yield call(addStaffProfile, request)

    let country = getCountryByID(response.CountryID)
    let state = getStateByID(response.StateID)
    let city = getCityByID(response.CityID)

    let result = {
      ...response,
      Classes: {
        ClassID: response.HomeClassID,
        Title: response.HomeClass,
      },
      Countries: {
        id: country ? Number(country.id) : 0,
        name: country ? country.name : "",
      },
      States: {
        id: state ? Number(state.id) : 0,
        name: state ? state.name : "",
      },
      Cities: {
        id: city ? Number(city.id) : 0,
        name: city ? city.name : "",
      },
      ZipCode: response.ZipCode ? response.ZipCode : "",
    }

    yield put(addStaffProfileSuccess(result))
    toast.success("Staff profile updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addStaffProfileFail(error))
    toast.error("Staff profile updation failed", { autoClose: 2000 })
  }
}

function* fetchHapeningTypes() {
  try {
    const response = yield call(getHappeningTypes)
    yield put(getHappeningTypesSuccess(response))
  } catch (error) {
    yield put(getHappeningTypesFail(error))
  }
}

function* fetchStaffHapenings({ payload: { staffID, filterDate } }) {
  try {
    const staff = {
      staffID: staffID,
      filterDate: filterDate,
    }
    const response = yield call(getStaffHappenings, staff)
    yield put(getStaffHappeningsSuccess(response))
  } catch (error) {
    yield put(getStaffHappeningsFail(error))
  }
}

function* onDeleteStaffHappening({ payload: staffHappening }) {
  try {
    const response = yield call(deleteStaffHappening, staffHappening)
    yield put(deleteStaffHappeningSuccess(response))
    toast.success("deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteStaffHappeningFail(error))
    toast.error("deletion failed", { autoClose: 2000 })
  }
}

function* onAddStaffHappening({ payload: staffHappening }) {
  try {
    const staffHappening1 = {
      ...staffHappening,
      ClassID: staffHappening?.Classes?.ClassID ?? null,
      HourTypeID: staffHappening?.HourTypes?.HourTypeID ?? null,
      Duration: staffHappening?.Hours?.hh + ":" + staffHappening?.Minutes?.mm,
    }

    const response = yield call(addStaffHappening, staffHappening1)
    yield put(addStaffHappeningSuccess(response))
    toast.success("Happening added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addStaffHappeningFail(error))
    toast.error("Happening failed", { autoClose: 2000 })
  }
}

function* fetchStaffHappeningDetail({ staffHappeningID }) {
  try {
    const response = yield call(getStaffHappeningDetail, staffHappeningID)
    let result = response[0]
    result = {
      ...result,
      Date: result.Date ? Moment(result.Date).format("DD MMM, YYYY") : "",
    }
    yield put(getStaffHappeningDetailSuccess(result))
  } catch (error) {
    yield put(getStaffHappeningDetailFail(error))
  }
}
function* onUpdateStaffHappening({ payload: staffHappening }) {
  try {
    const staffHappening1 = {
      ...staffHappening,
      ClassID: staffHappening?.Classes?.ClassID ?? null,
      HourTypeID: staffHappening?.HourTypes?.HourTypeID ?? null,
      Duration: staffHappening?.Hours?.hh + ":" + staffHappening?.Minutes?.mm,
    }
    const response = yield call(updateStaffHappening, staffHappening1)
    let result = response[0]
    yield put(updateStaffHappeningSuccess(result))
    toast.success("Updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateStaffHappeningFail(error))
    toast.error("Updation failed", { autoClose: 2000 })
  }
}

function* fetchHourTypes() {
  try {
    const response = yield call(getHourTypes)
    yield put(getHourTypesSuccess(response))
  } catch (error) {
    yield put(getHourTypesFail(error))
  }
}

function* fetchGenders() {
  try {
    const response = yield call(getGenders)
    yield put(getGendersSuccess(response))
  } catch (error) {
    yield put(getGendersFail(error))
  }
}

function* staffSaga() {
  yield takeEvery(GET_STAFFS, fetchStaffs)
  yield takeEvery(DELETE_STAFF, onDeleteStaff)
  yield takeEvery(ADD_NEW_STAFF, onAddNewStaff)
  yield takeEvery(GET_STAFF_DETAIL, fetchStaffDetail)
  yield takeEvery(UPDATE_STAFF, onUpdateStaff)
  yield takeEvery(UPDATE_STAFF_PHOTO, onUpdateStaffPhoto)
  yield takeEvery(RESET_STAFF, onResetStaff)

  // Payates
  yield takeEvery(GET_PAYRATES, fetchPayrates)
  yield takeEvery(DELETE_PAYRATE, onDeletePayrate)
  yield takeEvery(ADD_NEW_PAYRATE, onAddNewPayrate)
  yield takeEvery(GET_PAYRATE_DETAIL, fetchPayrateDetail)
  yield takeEvery(UPDATE_PAYRATE, onUpdatePayrate)
  yield takeEvery(RESET_PAYRATE, onResetPayrate)

  yield takeEvery(GET_EMPLOYMENT_TYPES, fetchEmployeementTypes)
  yield takeEvery(GET_CLASSIFICATION_TYPES, fetchClassificationTypes)
  yield takeEvery(ADD_STAFF_AVAILABILITY, onAddStaffAvailability)
  yield takeEvery(GET_STAFF_AVAILABILITY, fetchStaffAvailability)

  //Staff Profile
  yield takeEvery(GET_STAFF_PROFILE, fetchStaffProfile)
  yield takeEvery(ADD_STAFF_PROFILE, onAddStaffProfile)

  //  Staff Happening
  yield takeEvery(GET_HAPPENING_TYPES, fetchHapeningTypes)
  yield takeEvery(GET_STAFF_HAPPENING, fetchStaffHapenings)

  yield takeEvery(ADD_STAFF_HAPPENING, onAddStaffHappening)
  yield takeEvery(DELETE_STAFF_HAPPENING, onDeleteStaffHappening)
  yield takeEvery(GET_STAFF_HAPPENING_DETAIL, fetchStaffHappeningDetail)
  yield takeEvery(UPDATE_STAFF_HAPPENING, onUpdateStaffHappening)
  yield takeEvery(GET_HOUR_TYPES, fetchHourTypes)
  yield takeEvery(GET_GENDERS, fetchGenders)
}

export default staffSaga
